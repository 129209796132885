import { BillingCalculator, CashFlow, SystemCost, CashFlowSchedule, CashFlowParameters } from '../../types/custom';
import SIMULATION_PARAMETERS from '../context/SimulationParameters';
import { monthlyKwhToYen } from './billing';

export const emptyCashFlow: CashFlow = {
  panelsCount: 0,
  energyUsagePerYear: 0,
  paybackPeriod: 0,
  totalFitRevenue: 0,
  totalElectricSavings: 0,
  totalSavingsBuy: 0,
  totalSavingsLoan: 0,
  cashFlowSchedule: [
    {
      year: 1,
      fitRate: 0,
      fitRevenue: 0,
      yearlyElectricSavings: 0,
      totalElectricSavingsToDate: 0,
      totalFitRevenueToDate: 0,
      totalFitAndElectricToDate: 0,
      yearlyEnergyAcKwh: 0,
      selfConsumption: 0,
      energySold: 0,
    },
  ],
};

export const defaultParameters: CashFlowParameters = {
  selfConsumeRatio: 0.35,
  inflationRate: 2.342 / 100,
  maxRecommendedPanelsCount: SIMULATION_PARAMETERS.MAX_PANELS_COUNT,
  minRecommendedPanelsCount: SIMULATION_PARAMETERS.MIN_PANELS_COUNT,
  startYear: 1,
  endYear: 20,
  finalFitYear: 10,
  degradationRate: 1 - 0.5 / 100,
};

/**
   *
   * @param areaMeters2
   * @return {number} consumption in Kwh per year
   */
export const estimateConsumption = (areaMeters2: number): number => {
  if (areaMeters2 && !isNaN(areaMeters2)) {
    if (areaMeters2 < 50) {
      return 3523;
    } if (areaMeters2 < 80) {
      return 3826;
    } if (areaMeters2 < 120) {
      return 5316;
    } if (areaMeters2 < 150) {
      return 6835;
    }
    return 7839;
  }
  return 0;
};

const calcPaybackPeriod = (cashFlowSchedule: CashFlowSchedule[], upfrontCost: number): number => {
  let year = 1;
  cashFlowSchedule.forEach((cashFlow) => {
    if (cashFlow.totalFitAndElectricToDate < upfrontCost) {
      year += 1;
    }
  });
  return year;
};

const calcCashFlowForYear = (
  yearlyEnergyAcKwh: number,
  fitRate: number,
  maxSelfConsumption: number,
  originalElectricCost: number,
  year: number,
  totalElectricSavingsToDate: number,
  totalFitRevenueToDate: number,
  yearlyConsumption: number,
  billingCalculator: BillingCalculator,
  parameters: CashFlowParameters = defaultParameters,
) => {
  const selfConsumption = Math.min(yearlyEnergyAcKwh, maxSelfConsumption);
  const energySold = yearlyEnergyAcKwh - selfConsumption;
  const fitRevenue = fitRate * energySold;
  const reducedElectricCost = monthlyKwhToYen(billingCalculator, (yearlyConsumption - selfConsumption) / 12);
  // eslint-disable-next-line prefer-exponentiation-operator
  const yearlyElectricSavings = 12 * (originalElectricCost - reducedElectricCost) * Math.pow(1 + parameters.inflationRate, year - 1); // Energy cost inflate;
  const newTotalElectricSavingsToDate = totalElectricSavingsToDate + yearlyElectricSavings;
  const newTotalFitRevenueToDate = totalFitRevenueToDate + fitRevenue;
  return {
    year,
    fitRate,
    fitRevenue,
    yearlyElectricSavings,
    totalElectricSavingsToDate: newTotalElectricSavingsToDate,
    totalFitRevenueToDate: newTotalFitRevenueToDate,
    totalFitAndElectricToDate: newTotalFitRevenueToDate + newTotalElectricSavingsToDate,
    yearlyEnergyAcKwh,
    selfConsumption,
    energySold,
  };
};

const calcCashFlowSchedule = (yearlyEnergyAcKwh: number, maxSelfConsumption: number, originalElectricCost: number, yearlyConsumption: number, billingCalculator: BillingCalculator, parameters: CashFlowParameters = defaultParameters): CashFlowSchedule[] | [] => {
  const { startYear, endYear, finalFitYear, degradationRate, inflationRate } = parameters;

  const cashFlowSchedule: CashFlowSchedule[] = [];
  let totalElectricSavingsToDate = 0;
  let totalFitRevenueToDate = 0;

  let fitRate = billingCalculator.fit;
  let afterFitRate = billingCalculator.afterFit;
  let newYearlyEnergyAcKwh = yearlyEnergyAcKwh;
  for (let year = startYear; year <= endYear; year++) {
    fitRate = year <= finalFitYear ? fitRate : afterFitRate;
    afterFitRate *= (1 + inflationRate);
    newYearlyEnergyAcKwh *= degradationRate;

    const newCashFlowSchedule = calcCashFlowForYear(
      newYearlyEnergyAcKwh,
      fitRate,
      maxSelfConsumption,
      originalElectricCost,
      year,
      totalElectricSavingsToDate,
      totalFitRevenueToDate,
      yearlyConsumption,
      billingCalculator,
    );
    if (newCashFlowSchedule) {
      cashFlowSchedule.push(newCashFlowSchedule);
    }
    totalElectricSavingsToDate = cashFlowSchedule[cashFlowSchedule.length - 1].totalElectricSavingsToDate;
    totalFitRevenueToDate = cashFlowSchedule[cashFlowSchedule.length - 1].totalFitRevenueToDate;
  }
  return cashFlowSchedule;
};

export const calcSpecificCase = (systemCost: SystemCost, yearlyConsumption: number, billingCalculator: BillingCalculator, monthlyBill: number, parameters: CashFlowParameters = defaultParameters): CashFlow => {
  const maxSelfConsumption = yearlyConsumption * parameters.selfConsumeRatio;
  const cashFlowSchedule = calcCashFlowSchedule(systemCost.yearlyEnergyAcKwh, maxSelfConsumption, monthlyBill, yearlyConsumption, billingCalculator, parameters);
  // Total is last element in cashFlowSchedule
  const { totalFitAndElectricToDate } = cashFlowSchedule[cashFlowSchedule.length - 1];
  if (billingCalculator.type === 'timebased') {
    systemCost.upfrontCost = systemCost.loan.monthlyPayment * systemCost.loan.months;
  }
  const totalSavingsBuy = totalFitAndElectricToDate - systemCost.upfrontCost - systemCost.maintenanceCost + systemCost.incentiveDeduction;
  const totalSavingsLoan = totalSavingsBuy - systemCost.loan.totalInterest;
  const paybackPeriod = calcPaybackPeriod(
    cashFlowSchedule,
    systemCost.upfrontCost + systemCost.maintenanceCost - systemCost.incentiveDeduction,
  );

  const cashFlow: CashFlow = {
    panelsCount: systemCost.panelsCount,
    energyUsagePerYear: yearlyConsumption,
    paybackPeriod,
    totalFitRevenue: cashFlowSchedule[cashFlowSchedule.length - 1].totalFitRevenueToDate,
    totalElectricSavings: cashFlowSchedule[cashFlowSchedule.length - 1].totalElectricSavingsToDate,
    totalSavingsBuy,
    totalSavingsLoan,
    cashFlowSchedule,
  };
  return cashFlow;
};
