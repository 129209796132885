// 2024-09: 福岡市向けSuncleTOP開発
// 環境省の排出係数を根拠とし、0.438kgに変更
// 0.000438 (t-CO/kWh)
// 排出係数一覧(環境省)R6年提出用
// 電気事業者別排出係数における一般送配電事業者のCO2排出係数（全国平均係数）
const carbonDioxideKgPerKwh = 0.438;

const carbonDioxideKgPerLiter = 2.32;
const averageKmPerLiter = 9;
const averageMilagePerYear = 10575;
const carbonDioxideOffsetKgPerTree = 14;
const volumePerKgInCubeMeters = 509 / 1000;
const convertCubicMeterToMilliliter = 1000 * 1000;
const milliliterPerBottle = 500;
const cellPhoneKwhPerCharge = 0.0114;
const refrigeratorKwhPerYear = 291.1;
const riceCookerWhPerTime = 176.5;
const convertToKwh = 1 / 1000;

// eslint-disable-next-line
export const updateCarbonOffset = (yearlyEnergyAcKwh: number) => {
  const newCarbonOffsetKgPerDay = Math.max((carbonDioxideKgPerKwh * yearlyEnergyAcKwh) / 365, 0);
  return {
    carsRemovedPerYear: (Math.max((((newCarbonOffsetKgPerDay * 365) / carbonDioxideKgPerLiter) * averageKmPerLiter) / averageMilagePerYear, 0)).toLocaleString(undefined, { maximumFractionDigits: 2 }),
    treesSaved: (Math.max((newCarbonOffsetKgPerDay * 365) / carbonDioxideOffsetKgPerTree, 0)).toLocaleString(undefined, { maximumFractionDigits: 0 }),
    volumeInBottlesFilled: (Math.max((newCarbonOffsetKgPerDay * volumePerKgInCubeMeters * convertCubicMeterToMilliliter) / milliliterPerBottle, 0)).toLocaleString(undefined, { maximumFractionDigits: 0 }),
    // generation / 0.0114kWh
    cellPhoneCharges: (yearlyEnergyAcKwh / 365 / cellPhoneKwhPerCharge).toLocaleString(undefined, { maximumFractionDigits: 0 }), // Convert to daily charges
    // generation / 291.1 // YEARS
    refrigeratorHours: ((yearlyEnergyAcKwh / 365 / (refrigeratorKwhPerYear / 365)) * 24).toLocaleString(undefined, { maximumFractionDigits: 0 }), // convert year to hours
    // generation / 176.5kWh / 1000
    riceCooking: (yearlyEnergyAcKwh / 365 / (riceCookerWhPerTime * convertToKwh)).toLocaleString(undefined, { maximumFractionDigits: 0 }),
    carbonOffsetKgPerDay: newCarbonOffsetKgPerDay.toLocaleString(undefined, { maximumFractionDigits: 2 }),
  };
};
