import { SolarPanelConfig, IncentiveInfo } from '../../types/custom';

export const emptyIncentive: IncentiveInfo[] = [
  {
    city: '',
    prefecture: '',
    startDate: '',
    endDate: '',
    level: '',
    max: 0,
    name: '',
    structure: 0,
    type: 'None',
    url: '',
  },
];

/**
 * @description ・設置費用目安に対して設定した割合(%)を補助金として計算する。
 *              ・補助金データのStructureに設定した値は、DBへの登録時に%に変換される。
 *              ・計算した補助金とMaxのうち、小さい値を補助金とする。
 *
 *                例）Structureに0.1を設定した場合、DBには10(%)で登録される。
 *                例）設置費用目安が10万円、Structureが0.1の場合、補助金は1万円になる。
 * @param incentive IncentiveInfo model used for calculation.
 * @param cost 設置費用目安
 */
const percentOfPrice = (incentive: IncentiveInfo, cost: number): number => {
  if (typeof incentive.structure === 'number') {
    // Math.abs is used in case of user input error
    // e.g -20 for a 20 percent discount.
    return cost >= 0 ? Math.min(cost * (Math.abs(incentive.structure) / 100), incentive.max) : 0;
  }
  // Non valid structure for incentive type "PercentOfPrice"
  throw new Error('Structure Required');
  // TODO: check side effects of throwing error
  // return 0;
};

// slack #dev (March 28th, 2019)
// For a give structure of  “0 - 3 = 20,000 ; 3-10= 50,000”
// If the Type is YenPerKW the 3 will be part of the lower range and the 20,000 will apply.
// If Type is FixedYen than the 3 will be part of the upper range and the 50,000 will apply,

/**
   * @description Gives the incentive deduction for a given system size. If the
   *            system size is on the boundary of two ranges the upper range
   *            will be used for the cost.
   *
   *              ・範囲毎に設定された固定の金額を使用して
   *                システム容量が含まれる範囲の金額を補助金とする。
   *              ・システム容量が複数の範囲の境界にある場合、上の範囲の金額を使用する。
   *              ・計算した補助金とMaxのうち、小さい値を補助金とする。
   *
   *                例）Structureが「0-3=10000;3-10=20000」、システム容量が2kWの場合、補助金は1万円になる。
   *                例）Structureが「0-3=10000;3-10=20000」、システム容量が3kWの場合、補助金は2万円になる。
   * @param incentive IncentiveInfo model used for calculation.
   * @param panelConfig SolarPanelConfig as described in Generation Service.
   * @param averageGenerationPerPanel
   */
const fixedYen = (incentive: IncentiveInfo, panelConfig: SolarPanelConfig, averageGenerationPerPanel: number): number => {
  let result = 0;
  const generation = panelConfig.panelsCount * averageGenerationPerPanel;
  if (generation <= 0) {
    return 0;
  }
  if (Array.isArray(incentive.structure)) {
    incentive.structure.forEach((range) => {
      if (generation >= range.from && generation <= range.to) {
        result = range.value;
      }
    });
  }
  return Math.min(result, incentive.max);
};

/**
   * @description Gives the incentive deduction for a given system size. If the
   *            system size is on the boundary of two ranges the LOWER range
   *            will be used for the cost.
   *
   *              ・範囲毎に設定された1kWあたりの金額を使用して
   *                システム容量までの補助金を範囲毎に計算して合計する。
   *              ・システム容量が複数の範囲の境界にある場合、下の範囲の金額を使用する。
   *              ・計算した補助金とMaxのうち、小さい値を補助金とする。
   *
   *                例）Structureが「0-3=10000;3-10=20000」、システム容量が3kWの場合、補助金は3万円になる。
   *                    1万円 * 3kW = 3万円
   *                例）Structureが「0-3=10000;3-10=20000」、システム容量が4kWの場合、補助金は5万円になる。
   *                    (1万円 * 3kW) + (2万円 * 1kW) = 5万円
   * @param incentive IncentiveInfo model used for calculation.
   * @param panelConfig SolarPanelConfig as described in Generation Service.
   * @param averageGenerationPerPanel
   */
const yenPerKW = (incentive: IncentiveInfo, panelConfig: SolarPanelConfig, averageGenerationPerPanel: number): number => {
  let result = 0;
  const generation = panelConfig.panelsCount * averageGenerationPerPanel;
  if (generation <= 0 || (Array.isArray(incentive.structure) && incentive.structure.length === 0)) {
    return 0;
  }
  if (Array.isArray(incentive.structure)) {
    incentive.structure.forEach((range) => {
      if (generation >= range.to) {
        result += range.value * (range.to - range.from);
      } else if (generation > range.from) {
        result += range.value * (generation - range.from);
      } else {
      // Generation is less than this range.
        result += 0;
      }
    });
  }
  return Math.min(result, incentive.max);
};

// 2023-07-12: 補助金パターン追加（YenPerKWWithinRange）
/**
 * @description ・設定した範囲にシステム容量が含まれる場合のみ
 *                その範囲に設定した金額を使用して
 *                0kWからシステム容量までの範囲の補助金を計算する。
 *              ・システム容量が複数の範囲の境界にある場合、下の範囲の金額を使用する。
 *              ・計算した補助金とMaxのうち、小さい値を補助金とする。
 *
 *                例）Structureが「0-3=10000;3-10=20000」、システム容量が3kWの場合、補助金は3万円になる。
 *                    1万円 * 3kW = 3万円
 *                例）Structureが「0-3=10000;3-10=20000」、システム容量が4kWの場合、補助金は8万円になる。
 *                    2万円 * 4kW = 8万円
 *                例）範囲毎にMaxを設定したい場合、範囲毎にレコードを分けて登録する。
 *                  ・Structureが「0-3=10000」のレコード
 *                      システム容量が3kWの場合、補助金は3万円になる。
 *                      システム容量が4kWの場合、補助金は0万円になる。
 *                  ・Structureが「3-10=20000」のレコード
 *                      システム容量が3kWの場合、補助金は0万円になる。
 *                      システム容量が4kWの場合、補助金は8万円になる。
 * @param incentive IncentiveInfo model used for calculation.
 * @param panelConfig SolarPanelConfig as described in Generation Service.
 * @param averageGenerationPerPanel
 */
const yenPerKWWithinRange = (incentive: IncentiveInfo, panelConfig: SolarPanelConfig, averageGenerationPerPanel: number): number => {
  let result = 0;
  const generation = panelConfig.panelsCount * averageGenerationPerPanel;
  if (generation <= 0 || (Array.isArray(incentive.structure) && incentive.structure.length === 0)) {
    return 0;
  }
  if (Array.isArray(incentive.structure)) {
    incentive.structure.forEach((range) => {
      if (range.from < generation && generation <= range.to) {
        result = range.value * (generation);
      }
    });
  }
  if (incentive.max) {
    return Math.min(result, incentive.max);
  }
  return result;
};

/**
   * calcDeduction - determines the appropriate incentive model and returns
   *                 the total deduction for a given panelConfig.
   *
   * @param incentive IncentiveInfo used for calculation.
   * @param panelConfig A SolarPanelConfig as described in Generation Service.
   * @param totalCost Total cost of the system in yen. In case of a loan this excludes interest.
   */

export const calcDeduction = (incentives: IncentiveInfo[], panelConfig: SolarPanelConfig, totalCost: number, averageGenerationPerPanel: number): { totalDeduction: number; deductionArray: number[] } => {
  let totalDeduction = 0;
  const deductionArray: number[] = [];
  incentives.forEach((incentive) => {
    let subTotal = 0;
    switch (incentive.type) {
      case 'PercentOfPrice':
        subTotal = percentOfPrice(incentive, totalCost);
        deductionArray.push(subTotal);
        totalDeduction += subTotal;
        break;
      case 'FixedYen':
        subTotal = fixedYen(incentive, panelConfig, averageGenerationPerPanel);
        deductionArray.push(subTotal);
        totalDeduction += subTotal;
        break;
      case 'YenPerKW':
        subTotal = yenPerKW(incentive, panelConfig, averageGenerationPerPanel);
        deductionArray.push(subTotal);
        totalDeduction += subTotal;
        break;
      case 'YenPerKWWithinRange':
        // 2023-07-12: 補助金パターン追加（YenPerKWWithinRange）
        subTotal = yenPerKWWithinRange(incentive, panelConfig, averageGenerationPerPanel);
        deductionArray.push(subTotal);
        totalDeduction += subTotal;
        break;
      default:
        // No incentive type or no incentive in area
        subTotal = 0;
        deductionArray.push(subTotal);
        totalDeduction += subTotal;
    }
  });
  return { totalDeduction, deductionArray };
};
