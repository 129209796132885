import React, { useContext } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import NotificationContext from '../../lib/context/NotificationContext';

function Alert(props: AlertProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert data-testid="notification" elevation={6} variant="filled" {...props} />;
}

const Notification: React.FC = () => {
  const { state: { color, message, isOpen }, dispatch } = useContext(NotificationContext);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch({ type: 'CLOSE' });
  };

  return (
    <Snackbar open={isOpen} autoHideDuration={3000} onClose={handleClose}>
      <Alert onClose={handleClose} color={color}>{message}</Alert>
    </Snackbar>
  );
};

export default Notification;
