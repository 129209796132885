import React, { createContext, useReducer, useEffect, useContext, useState } from 'react';
import SimulationContext from './SimulationContext';
import defaultGeocode from '../data/defaultGeocode';

type SearchAddressContextArgs = {
  state: SearchAddressContextState;
  dispatch: React.Dispatch<SearchAddressContextAction>;
};

type SearchAddressContextState = {
  address: string;
  sessionToken: google.maps.places.AutocompleteSessionToken | undefined;
};

type SearchAddressContextAction = {
  type: 'SET';
  payload: {
    address?: string,
    sessionToken?: google.maps.places.AutocompleteSessionToken;
  };
};

const SearchAddressReducer = (state: SearchAddressContextState, action: SearchAddressContextAction) => {
  switch (action.type) {
    case 'SET':
      return {
        ...state,
        address: action.payload.address !== undefined ? action.payload.address : state.address,
        sessionToken: action.payload.sessionToken || state.sessionToken,
      };
    default:
      throw new Error();
  }
};

const SearchAddressContext = createContext({} as SearchAddressContextArgs);

const initialState: SearchAddressContextState = {
  address: '',
  sessionToken: undefined,
};

export const SearchAddressContextProvider: React.FC = ({ children }) => {
  const { state: { geocodeData } } = useContext(SimulationContext);
  const [preloaded, setPreloaded] = useState(false);
  const [state, dispatch] = useReducer(SearchAddressReducer, initialState);
  const value = { state, dispatch };
  SearchAddressContext.displayName = 'SearchAddressContext';

  useEffect(() => {
    if (!preloaded
      && !state.address
      && geocodeData
      && geocodeData.lat.toFixed(6) !== defaultGeocode.lat.toFixed(6)
      && geocodeData.lng.toFixed(6) !== defaultGeocode.lng.toFixed(6)
      && geocodeData.completeAddress) {
      setPreloaded(true);
      dispatch({ type: 'SET', payload: { address: geocodeData.completeAddress.split(' ')[1] } });
    }
  }, [geocodeData]);

  return (
    <SearchAddressContext.Provider value={value}>{children}</SearchAddressContext.Provider>
  );
};

export default SearchAddressContext;
