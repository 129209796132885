/* eslint-disable react/no-array-index-key */
import React, { useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { colors, mediaQueries as mq } from '../../lib/styles';

const useStyles = makeStyles(() => ({
  DotHeader: {
    textAlign: 'center',
    fontWeight: 700,
    lineHeight: 1.6,
    margin: 0,
    [mq.smOnly]: {
      fontSize: 24,
    },
    [mq.mdUp]: {
      fontSize: 40,
    },
  },
  'DotHeader--alignLeft': {
    [mq.mdUp]: {
      textAlign: 'left',
    },
    '& $DotHeader__dot': {
      '&::after': {
        backgroundColor: colors.purple,
      },
      '&:nth-child(even)::after': {
        backgroundColor: colors.orange,
      },
    },
  },
  isAnimated: {
    '& $DotHeader__dot:nth-child(1)::after': {
      animation: '$jump 2s ease normal 0s none running',
    },
    '& $DotHeader__dot:nth-child(2)::after': {
      animation: '$jump 2s ease normal 0.2s none running',
    },
    '& $DotHeader__dot:nth-child(3)::after': {
      animation: '$jump 2s ease normal 0.4s none running',
    },
    '& $DotHeader__dot:nth-child(4)::after': {
      animation: '$jump 2s ease normal 0.6s none running',
    },
    '& $DotHeader__dot:nth-child(5)::after': {
      animation: '$jump 2s ease normal 0.8s none running',
    },
    '& $DotHeader__dot:nth-child(6)::after': {
      animation: '$jump 2s ease normal 1s none running',
    },
    '& $DotHeader__dot:nth-child(7)::after': {
      animation: '$jump 2s ease normal 1.2s none running',
    },
    '& $DotHeader__dot:nth-child(8)::after': {
      animation: '$jump 2s ease normal 1.4s none running',
    },
    '& $DotHeader__dot:nth-child(9)::after': {
      animation: '$jump 2s ease normal 1.6s none running',
    },
    '& $DotHeader__dot:nth-child(10)::after': {
      animation: '$jump 2s ease normal 1.8s none running',
    },
    '& $DotHeader__dot:nth-child(11)::after': {
      animation: '$jump 2s ease normal 1.9s none running',
    },
  },
  DotHeader__dot: {
    position: 'relative',
    '&::after': {
      display: 'block',
      content: '""',
      backgroundColor: colors.orange,
      borderRadius: '50%',
      position: 'absolute',
      left: '50%',
      [mq.smOnly]: {
        width: 5,
        height: 5,
        top: -8,
        marginLeft: -3,
      },
      [mq.mdUp]: {
        width: 10,
        height: 10,
        top: -14,
        marginLeft: -5,
      },
    },
    '&:nth-child(even)::after': {
      backgroundColor: colors.purple,
    },
  },
  '@keyframes jump': {
    '0%, 100%, 20%, 50%, 80%': {
      transform: 'translateY(0px)',
    },
    '40%': {
      transform: 'translateY(-15px)',
    },
    '60%': {
      transform: 'translateY(-7px)',
    },
  },
  DotHeader__underline: {
    display: 'block',
    margin: '0 auto',
    [mq.smOnly]: {
      height: 5,
      width: 54,
      marginTop: 16,
    },
    [mq.mdUp]: {
      height: 10,
      width: 110,
      marginTop: 24,
    },
    position: 'relative',
    '&::before, &::after': {
      display: 'block',
      content: '""',
      transform: 'skewX(-15deg)',
      position: 'absolute',
      [mq.smOnly]: {
        height: 5,
      },
      [mq.mdUp]: {
        height: 10,
      },
    },
    '&::before': {
      backgroundColor: colors.orange,
      [mq.smOnly]: {
        width: 54,
      },
      [mq.mdUp]: {
        width: 110,
      },
    },
    '&::after': {
      backgroundColor: colors.purple,
      right: 0,
      [mq.smOnly]: {
        width: 27,
      },
      [mq.mdUp]: {
        width: 55,
      },
    },
  },
  'DotHeader__underline--alignLeft': {
    [mq.mdUp]: {
      margin: '24px 0 0',
    },
  },
}));

const flatten = (acc: any[], item: any[]) => {
  return [...acc, ...item];
};

type DotHeaderProps = {
  dottedWords?: string[];
  lines: string[];
  tag?: 'h1' | 'h2';
  align?: 'left'
};

const DotHeader: React.FC<DotHeaderProps> = ({ lines, dottedWords, tag: Tag = 'h2', align }) => {
  const headerRef = useRef<HTMLHeadingElement | null>(null);
  const [isAnimated, setIsAnimated] = useState(false);
  const [content, setContent] = useState<string>();
  const classes = useStyles();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const alreadyInView = (headerRef.current?.offsetTop || 0) - window.innerHeight + 200 < window.scrollY;

    const scrollCb = () => {
      if (!isAnimated && headerRef.current?.offsetTop) {
        if ((headerRef.current?.offsetTop || 0) - window.innerHeight + 200 < window.scrollY) {
          setIsAnimated(true);
        }
      }
    };

    if (alreadyInView) {
      setIsAnimated(true);
    } else {
      window.addEventListener('scroll', scrollCb);
      return () => window.removeEventListener('scroll', scrollCb);
    }
  }, [headerRef]);

  useEffect(() => {
    if (dottedWords) {
      const usedWords: string[] = [];
      setContent(lines.map((line, lineIndex) => {
        let toSplit = line;
        const wordsToDot = dottedWords.filter((word) => !usedWords.includes(word));
        let dottedWord: any[] = [];
        if (wordsToDot.length) {
          dottedWord = wordsToDot.map((word, wordIndex) => {
            if (line.includes(word)) {
              usedWords.push(word);
              const nextPiece = word.split('').map((char, index) => <span key={`${char.charCodeAt(0)}-${index}`} className={classes.DotHeader__dot}>{char}</span>);
              const [start, end] = toSplit.split(word);
              toSplit = end;
              return wordIndex === wordsToDot.length - 1 ? [start, ...nextPiece, end] : [start, ...nextPiece];
            }
            return [toSplit];
          });
        } else {
          dottedWord = [line];
        }
        return lineIndex === lines.length - 1 ? dottedWord.reduce(flatten, []) : [...dottedWord.reduce(flatten, []), <br key={`${lineIndex}-br`} />];
      }).reduce(flatten, []));
    } else {
      setContent(lines[0]);
    }
  }, [lines, dottedWords]);

  return (
    <>
      <Tag
        className={clsx(classes.DotHeader, isAnimated && classes.isAnimated, align && classes['DotHeader--alignLeft'])}
        ref={headerRef}
        data-testid={`${Tag}tag`}
      >{content}
      </Tag>
      <span className={clsx(classes.DotHeader__underline, align && classes['DotHeader__underline--alignLeft'])} />
    </>
  );
};

DotHeader.defaultProps = {
  dottedWords: undefined,
  tag: 'h2',
  align: undefined,
};

export default DotHeader;
