import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { mediaQueries as mq } from '../../lib/styles';

const useStyles = makeStyles(() => ({
  FormWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 600,
    margin: '0 auto',
    [mq.smOnly]: {
      marginTop: 24,
    },
    [mq.mdUp]: {
      marginTop: 40,
    },
  },
}));

const FormWrap: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.FormWrap}>{children}</div>
  );
};

export default FormWrap;
