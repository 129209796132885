export default {
  default: true,
  // center: {
  lat: 35.41895490356026,
  lng: 139.6610466579386,
  prefecture: '埼玉県',
  // lat: 35.9817008972168,
  // lng: 139.36009216308594,
  city: { city: '鳩山町', aal2: '比企郡' },
  postcode: '350',
  postcodeFull: '350-0312',
  completeAddress: '',
  plus_code: { compound_code: 'X9J6+M2 日本、埼玉県鳩山町', global_code: '8Q7XX9J6+M2' },
  results: [
    {
      address_components: [
        { long_name: '７', short_name: '７', types: ['premise'] },
        { long_name: '７', short_name: '７', types: ['political', 'sublocality', 'sublocality_level_4'] },
        { long_name: '３丁目', short_name: '３丁目', types: ['political', 'sublocality', 'sublocality_level_3'] },
        { long_name: '鳩ヶ丘', short_name: '鳩ヶ丘', types: ['political', 'sublocality', 'sublocality_level_2'] },
        { long_name: '鳩山町', short_name: '鳩山町', types: ['locality', 'political'] },
        { long_name: '比企郡', short_name: '比企郡', types: ['administrative_area_level_2', 'political'] },
        { long_name: '埼玉県', short_name: '埼玉県', types: ['administrative_area_level_1', 'political'] },
        { long_name: '日本', short_name: 'JP', types: ['country', 'political'] },
        { long_name: '350-0312', short_name: '350-0312', types: ['postal_code'] },
      ],
      formatted_address: '日本、〒350-0312 埼玉県比企郡鳩山町鳩ヶ丘３丁目７−７',
      geometry: {
        bounds: { northeast: { lat: 35.9817554, lng: 139.3601702 }, southwest: { lat: 35.981653, lng: 139.3600133 } },
        location: { lat: 35.9817048, lng: 139.3600896 },
        location_type: 'ROOFTOP',
        viewport: {
          northeast: { lat: 35.98305318029151, lng: 139.3614407302915 },
          southwest: { lat: 35.98035521970851, lng: 139.3587427697085 },
        },
      },
      place_id: 'ChIJ_dYdgXMqGWAR9ns4NxqNFcc',
      types: ['premise'],
    },
    {
      address_components: [
        { long_name: 'とちのき通り', short_name: 'とちのき通り', types: ['route'] },
        { long_name: '７', short_name: '７', types: ['political', 'sublocality', 'sublocality_level_4'] },
        { long_name: '３丁目', short_name: '３丁目', types: ['political', 'sublocality', 'sublocality_level_3'] },
        { long_name: '鳩ヶ丘', short_name: '鳩ヶ丘', types: ['political', 'sublocality', 'sublocality_level_2'] },
        { long_name: '鳩山町', short_name: '鳩山町', types: ['locality', 'political'] },
        { long_name: '比企郡', short_name: '比企郡', types: ['administrative_area_level_2', 'political'] },
        { long_name: '埼玉県', short_name: '埼玉県', types: ['administrative_area_level_1', 'political'] },
        { long_name: '日本', short_name: 'JP', types: ['country', 'political'] },
        { long_name: '350-0312', short_name: '350-0312', types: ['postal_code'] },
      ],
      formatted_address: '日本、〒350-0312 埼玉県比企郡鳩山町鳩ヶ丘３丁目７ とちのき通り',
      geometry: {
        bounds: { northeast: { lat: 35.9821468, lng: 139.3602001 }, southwest: { lat: 35.9814802, lng: 139.3591481 } },
        location: { lat: 35.9818135, lng: 139.3596741 },
        location_type: 'GEOMETRIC_CENTER',
        viewport: {
          northeast: { lat: 35.9831624802915, lng: 139.3610230802915 },
          southwest: { lat: 35.9804645197085, lng: 139.3583251197085 },
        },
      },
      place_id: 'ChIJHyY9hXMqGWARCsgzYtxKPZI',
      types: ['route'],
    },
    {
      address_components: [
        { long_name: '350-0312', short_name: '350-0312', types: ['postal_code'] },
        { long_name: '鳩山町', short_name: '鳩山町', types: ['locality', 'political'] },
        { long_name: '比企郡', short_name: '比企郡', types: ['administrative_area_level_2', 'political'] },
        { long_name: '埼玉県', short_name: '埼玉県', types: ['administrative_area_level_1', 'political'] },
        { long_name: '日本', short_name: 'JP', types: ['country', 'political'] },
      ],
      formatted_address: '日本 〒350-0312',
      geometry: {
        bounds: { northeast: { lat: 35.9865428, lng: 139.3652476 }, southwest: { lat: 35.9791726, lng: 139.3503 } },
        location: { lat: 35.9834433, lng: 139.3564312 },
        location_type: 'APPROXIMATE',
        viewport: { northeast: { lat: 35.9865428, lng: 139.3652476 }, southwest: { lat: 35.9791726, lng: 139.3503 } },
      },
      place_id: 'ChIJz9P1-XEqGWARN7SHRLC85Vo',
      types: ['postal_code'],
    },
    {
      address_components: [
        { long_name: '３丁目', short_name: '３丁目', types: ['political', 'sublocality', 'sublocality_level_3'] },
        { long_name: '鳩ヶ丘', short_name: '鳩ヶ丘', types: ['political', 'sublocality', 'sublocality_level_2'] },
        { long_name: '鳩山町', short_name: '鳩山町', types: ['locality', 'political'] },
        { long_name: '比企郡', short_name: '比企郡', types: ['administrative_area_level_2', 'political'] },
        { long_name: '埼玉県', short_name: '埼玉県', types: ['administrative_area_level_1', 'political'] },
        { long_name: '日本', short_name: 'JP', types: ['country', 'political'] },
        { long_name: '350-0312', short_name: '350-0312', types: ['postal_code'] },
      ],
      formatted_address: '日本、〒350-0312 埼玉県比企郡鳩山町鳩ヶ丘３丁目',
      geometry: {
        bounds: { northeast: { lat: 35.983902, lng: 139.3652479 }, southwest: { lat: 35.9806681, lng: 139.3591329 } },
        location: { lat: 35.9824072, lng: 139.3608728 },
        location_type: 'APPROXIMATE',
        viewport: { northeast: { lat: 35.983902, lng: 139.3652479 }, southwest: { lat: 35.9806681, lng: 139.3591329 } },
      },
      place_id: 'ChIJ6Xo5-HQqGWAR_BS59Bh35p4',
      types: ['political', 'sublocality', 'sublocality_level_3'],
    },
    {
      address_components: [
        { long_name: '７', short_name: '７', types: ['political', 'sublocality', 'sublocality_level_4'] },
        { long_name: '３丁目', short_name: '３丁目', types: ['political', 'sublocality', 'sublocality_level_3'] },
        { long_name: '鳩ヶ丘', short_name: '鳩ヶ丘', types: ['political', 'sublocality', 'sublocality_level_2'] },
        { long_name: '鳩山町', short_name: '鳩山町', types: ['locality', 'political'] },
        { long_name: '比企郡', short_name: '比企郡', types: ['administrative_area_level_2', 'political'] },
        { long_name: '埼玉県', short_name: '埼玉県', types: ['administrative_area_level_1', 'political'] },
        { long_name: '日本', short_name: 'JP', types: ['country', 'political'] },
        { long_name: '350-0312', short_name: '350-0312', types: ['postal_code'] },
      ],
      formatted_address: '日本、〒350-0312 埼玉県比企郡鳩山町鳩ヶ丘３丁目７',
      geometry: {
        bounds: { northeast: { lat: 35.9823129, lng: 139.3603436 }, southwest: { lat: 35.9814739, lng: 139.3591334 } },
        location: { lat: 35.9819023, lng: 139.3597227 },
        location_type: 'GEOMETRIC_CENTER',
        viewport: {
          northeast: { lat: 35.98324238029149, lng: 139.3610874802915 },
          southwest: { lat: 35.9805444197085, lng: 139.3583895197085 },
        },
      },
      place_id: 'ChIJRZ92hXMqGWARmsTMQ8dTqLE',
      types: ['political', 'sublocality', 'sublocality_level_4'],
    },
    {
      address_components: [
        { long_name: '鳩ヶ丘', short_name: '鳩ヶ丘', types: ['political', 'sublocality', 'sublocality_level_2'] },
        { long_name: '鳩山町', short_name: '鳩山町', types: ['locality', 'political'] },
        { long_name: '比企郡', short_name: '比企郡', types: ['administrative_area_level_2', 'political'] },
        { long_name: '埼玉県', short_name: '埼玉県', types: ['administrative_area_level_1', 'political'] },
        { long_name: '日本', short_name: 'JP', types: ['country', 'political'] },
        { long_name: '350-0312', short_name: '350-0312', types: ['postal_code'] },
      ],
      formatted_address: '日本、〒350-0312 埼玉県比企郡鳩山町鳩ヶ丘',
      geometry: {
        bounds: { northeast: { lat: 35.986543, lng: 139.3652479 }, southwest: { lat: 35.979173, lng: 139.3503 } },
        location: { lat: 35.9834433, lng: 139.3564312 },
        location_type: 'APPROXIMATE',
        viewport: { northeast: { lat: 35.986543, lng: 139.3652479 }, southwest: { lat: 35.979173, lng: 139.3503 } },
      },
      place_id: 'ChIJt0bYf3EqGWARVtDvAqEpA-I',
      types: ['political', 'sublocality', 'sublocality_level_2'],
    },
    {
      address_components: [
        { long_name: '鳩山ニュータウン', short_name: '鳩山ニュータウン', types: ['neighborhood', 'political'] },
        { long_name: '鳩山町', short_name: '鳩山町', types: ['locality', 'political'] },
        { long_name: '比企郡', short_name: '比企郡', types: ['administrative_area_level_2', 'political'] },
        { long_name: '埼玉県', short_name: '埼玉県', types: ['administrative_area_level_1', 'political'] },
        { long_name: '日本', short_name: 'JP', types: ['country', 'political'] },
      ],
      formatted_address: '日本、埼玉県比企郡鳩山町 鳩山ニュータウン',
      geometry: {
        bounds: { northeast: { lat: 35.9955563, lng: 139.3652476 }, southwest: { lat: 35.9791726, lng: 139.3478514 } },
        location: { lat: 35.9876041, lng: 139.3539494 },
        location_type: 'APPROXIMATE',
        viewport: { northeast: { lat: 35.9955563, lng: 139.3652476 }, southwest: { lat: 35.9791726, lng: 139.3478514 } },
      },
      place_id: 'ChIJhUPrs3oqGWARMtyFAcnA-Io',
      types: ['neighborhood', 'political'],
    },
    {
      address_components: [
        { long_name: '鳩山町', short_name: '鳩山町', types: ['locality', 'political'] },
        { long_name: '比企郡', short_name: '比企郡', types: ['administrative_area_level_2', 'political'] },
        { long_name: '埼玉県', short_name: '埼玉県', types: ['administrative_area_level_1', 'political'] },
        { long_name: '日本', short_name: 'JP', types: ['country', 'political'] },
      ],
      formatted_address: '日本、埼玉県比企郡鳩山町',
      geometry: {
        bounds: { northeast: { lat: 36.01017590000001, lng: 139.3792519 }, southwest: { lat: 35.961668, lng: 139.2872389 } },
        location: { lat: 35.9816953, lng: 139.3342705 },
        location_type: 'APPROXIMATE',
        viewport: { northeast: { lat: 36.01017590000001, lng: 139.3792519 }, southwest: { lat: 35.961668, lng: 139.2872389 } },
      },
      place_id: 'ChIJed5O21cqGWARK3vzFeGeVTI',
      types: ['locality', 'political'],
    },
    {
      address_components: [
        { long_name: '比企郡', short_name: '比企郡', types: ['administrative_area_level_2', 'political'] },
        { long_name: '埼玉県', short_name: '埼玉県', types: ['administrative_area_level_1', 'political'] },
        { long_name: '日本', short_name: 'JP', types: ['country', 'political'] },
      ],
      formatted_address: '日本、埼玉県比企郡',
      geometry: {
        bounds: { northeast: { lat: 36.1105233, lng: 139.5454303 }, southwest: { lat: 35.9479725, lng: 139.1692432 } },
        location: { lat: 36.0293859, lng: 139.3044253 },
        location_type: 'APPROXIMATE',
        viewport: { northeast: { lat: 36.1105233, lng: 139.5454303 }, southwest: { lat: 35.9479725, lng: 139.1692432 } },
      },
      place_id: 'ChIJPbyMya0qGWARujP48UDcZeo',
      types: ['administrative_area_level_2', 'political'],
    },
    {
      address_components: [
        { long_name: '埼玉県', short_name: '埼玉県', types: ['administrative_area_level_1', 'political'] },
        { long_name: '日本', short_name: 'JP', types: ['country', 'political'] },
      ],
      formatted_address: '日本、埼玉県',
      geometry: {
        bounds: { northeast: { lat: 36.2834814, lng: 139.9001282 }, southwest: { lat: 35.7535048, lng: 138.7113448 } },
        location: { lat: 35.9962513, lng: 139.4466005 },
        location_type: 'APPROXIMATE',
        viewport: { northeast: { lat: 36.2834814, lng: 139.9001282 }, southwest: { lat: 35.7535048, lng: 138.7113448 } },
      },
      place_id: 'ChIJ6QF7R40qGWARbFKAqmcS2f4',
      types: ['administrative_area_level_1', 'political'],
    },
    {
      address_components: [
        { long_name: '本州', short_name: '本州', types: ['establishment', 'natural_feature', 'political'] },
        { long_name: '日本', short_name: 'JP', types: ['country', 'political'] },
      ],
      formatted_address: '日本、本州',
      geometry: {
        bounds: { northeast: { lat: 41.54974139999999, lng: 142.0844082 }, southwest: { lat: 33.4292734, lng: 130.851358 } },
        location: { lat: 36.16999990000001, lng: 138.04 },
        location_type: 'APPROXIMATE',
        viewport: { northeast: { lat: 41.54974139999999, lng: 142.0844082 }, southwest: { lat: 33.4292734, lng: 130.851358 } },
      },
      place_id: 'ChIJ0--zEyj8HGARMuZA4a7I1fo',
      types: ['establishment', 'natural_feature', 'political'],
    },
    {
      address_components: [{ long_name: '日本', short_name: 'JP', types: ['country', 'political'] }],
      formatted_address: '日本',
      geometry: {
        bounds: { northeast: { lat: 45.6412626, lng: 154.0031455 }, southwest: { lat: 20.3585295, lng: 122.8554688 } },
        location: { lat: 36.204824, lng: 138.252924 },
        location_type: 'APPROXIMATE',
        viewport: { northeast: { lat: 45.6412626, lng: 154.0031455 }, southwest: { lat: 20.3585295, lng: 122.8554688 } },
      },
      place_id: 'ChIJLxl_1w9OZzQRRFJmfNR1QvU',
      types: ['country', 'political'],
    },
  ],
  status: 'OK',
};
