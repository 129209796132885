// シミュレーションパラメータ（フロントエンド側）
const SIMULATION_PARAMETERS = {
  // パネル1枚あたりの発電量（kW）
  POWER_GENERATION_PER_PANEL: 0.4,

  // 最小パネル枚数（システム容量1.6kW）
  MIN_PANELS_COUNT: 4,

  // 最大パネル枚数（システム容量10kW）
  MAX_PANELS_COUNT: 25,

  // パネル枚数の初期値（ソーラーデータなしの場合、システム容量4.8kW）
  DEFAULT_PANELS_COUNT: 12,
};

export default SIMULATION_PARAMETERS;
