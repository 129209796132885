import { Amortization, LoanInfo, SystemParameters } from '../../types/custom';
import SIMULATION_PARAMETERS from '../context/SimulationParameters';

export const defaultParameters: SystemParameters = {
  // 2024-03-06: シャープ様からの依頼による変更対応
  // システム容量1kWあたりの設置費用を267000円から284000円に変更
  averageCostPerPanel: 284000 * SIMULATION_PARAMETERS.POWER_GENERATION_PER_PANEL,
  assumedAPR: 0.025,
  assumedLoanLengthMonths: 180,
};

/**
   * Multiplies the panelCount by the averageCostPerPanel;
   *
   * @param {number} panelsCount the amount of panels for the SolarPanelConfig
   */
export const getUpfrontCost = (panelsCount: number, params = defaultParameters): number => {
  return panelsCount * params.averageCostPerPanel;
};

/**
   * Calculates the monthly bill for the assumed loan length in months.
   *
   * Assumes interest is compounded monthly, payments are monthly, and no
   * advanced payments. Rounds any partial yen amount up to the next whole
   * integer.
   *
   * @param {number} upfrontCost - the upfront cost of the entire system in yen.
   */
const calcMonthlyPayment = (upfrontCost: number, params = defaultParameters): number => {
  // Amortization Equation: https://en.wikipedia.org/wiki/Amortization_calculator
  const monthlyPR = params.assumedAPR / 12;
  // eslint-disable-next-line prefer-exponentiation-operator
  const upperAmortEq = monthlyPR * Math.pow(1 + monthlyPR, params.assumedLoanLengthMonths);
  // eslint-disable-next-line prefer-exponentiation-operator
  const lowerAmortEq = Math.pow(1 + monthlyPR, params.assumedLoanLengthMonths) - 1;
  return Math.ceil(upfrontCost * (upperAmortEq / lowerAmortEq));
};

/**
   * Creates an array of Amortization objects.
   *
   * We are currently only returning the amortization schedule at yearly
   * intervals. The calculations however do calculate the amortization
   * schedule for each month. The final payment is adjusted because this
   * amount will always be lower due to rounding the monthly payment up in
   * calcMonthlyPayment method.
   *
   *
   * @param {number} upfrontCost - the upfront cost of the entire system
   * @param {number} monthlyPayment - monthly payment for the loan in yen.
   */
const calcAmortSchedule = (upfrontCost: number, monthlyPayment: number, params = defaultParameters): Amortization[] => {
  // ensure type safety by assigning type on left side of equation
  const firstAmort: Amortization = {
    payment: 0,
    principal: 0,
    interest: 0,
    totalInterest: 0,
    balance: upfrontCost,
  };
  const amortizations: Amortization[] = [firstAmort];
  const monthlyPR: number = params.assumedAPR / 12;
  let balance: number = upfrontCost;
  let totalInterest = 0;
  // Adds to the amortization schedule at the end of each year.
  for (let monthCount = 1; monthCount <= params.assumedLoanLengthMonths; monthCount++) {
    const interest: number = balance * monthlyPR;
    totalInterest += interest;
    const payment: number = Math.min(monthlyPayment, balance + interest); // Adjust for final payment
    const principal: number = Math.min(monthlyPayment - interest, balance); // Adjust for final payment
    balance -= principal;
    if (monthCount % 12 === 0) {
      const amort: Amortization = {
        payment,
        principal,
        interest,
        totalInterest,
        balance: Math.max(balance, 0),
      };
      amortizations.push(amort);
    }
  }
  return amortizations;
};

/**
   * Creates a LoanInfo object by calling other methods to calculate the
   * various fields.
   *
   * @param {number} upfrontCost - the upfront cost of the entire system
   */
export const loanCost = (upfrontCost: number, params = defaultParameters, customMonthlyPaymentFunction?: () => number): LoanInfo => {
  const monthlyPayment = customMonthlyPaymentFunction ? customMonthlyPaymentFunction() : calcMonthlyPayment(upfrontCost);
  const amortSchedule = calcAmortSchedule(upfrontCost, monthlyPayment);
  const loan: LoanInfo = {
    months: params.assumedLoanLengthMonths,
    monthlyPayment,
    principal: upfrontCost,
    totalInterest: amortSchedule[amortSchedule.length - 1].totalInterest,
    yearlyAmortizationSchedule: amortSchedule,
  };
  return loan;
};
