import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { mediaQueries as mq } from '../../lib/styles';
import dot from './dot.png';

const useStyles = makeStyles(() => ({
  DotSubHeading: {
    textAlign: 'center',
    marginTop: 0,
    marginBottom: 0,
    [mq.smOnly]: {
      fontSize: 18,
    },
    [mq.mdUp]: {
      fontSize: 28,
    },
    '& + $DotSubHeading': {
      marginTop: 0,
    },
  },
  DotSubHeading__dots: {
    display: 'inline-block',
    backgroundImage: `url("${dot}")`,
    backgroundRepeat: 'repeat-x',
    backgroundPositionY: 'bottom',
    paddingBottom: '0.1em',
  },
}));

const DotSubHeading: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <h3 className={classes.DotSubHeading}><span className={classes.DotSubHeading__dots}>{children}</span></h3>
  );
};

export default DotSubHeading;
