import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { colors, mediaQueries as mq } from '../../lib/styles';

const useStyles = makeStyles(() => ({
  ParagraphSmallText: {
    fontFamily: "'游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'Noto Sans JP', 'ヒラギノ角ゴ ProN' , 'Hiragino Kaku Gothic ProN' , '游ゴシック' , '游ゴシック体' , YuGothic , 'Yu Gothic' , 'メイリオ' , Meiryo , 'ＭＳ ゴシック' , 'MS Gothic' , HiraKakuProN-W3 , 'TakaoExゴシック' , TakaoExGothic , 'MotoyaLCedar' , 'Droid Sans Japanese' , sans-serif",
    letterSpacing: '-1px',
    fontSize: '0.875em',
    margin: '0',
    marginBottom: '1.5em',
    fontWeight: 500,
    color: `${colors.textGray}`,

    [mq.smOnly]: {
    },
    [mq.mdOnly]: {
    },
    [mq.lgOnly]: {
    },
  },
}));

type ParagraphSmallTextProps = {
  className?: string;
};

const ParagraphSmallText: React.FC<ParagraphSmallTextProps> = ({ children, className }) => {
  const classes = useStyles();

  return (
    <p className={clsx(classes.ParagraphSmallText, className)}>{children}</p>
  );
};

ParagraphSmallText.defaultProps = {
  className: undefined,
};

export default ParagraphSmallText;
