import { useEffect, useReducer } from 'react';
import Chart, { ChartData, ChartOptions } from 'chart.js';

type ChartReducerState = {
  chart?: Chart;
  chartData: ChartData;
  chartOptions: ChartOptions;
  chartType?: 'bar' | 'doughnut';
  canvasRef?: React.RefObject<HTMLCanvasElement | null>;
};

type ChartReducerAction = {
  type: 'SET',
  payload: {
    chartData?: ChartData;
    chartOptions?: ChartOptions;
    chartType?: 'bar' | 'doughnut';
    chart?: Chart;
    canvasRef?: React.RefObject<HTMLCanvasElement | null>;
  }
};

const chartReducer = (state: ChartReducerState, action: ChartReducerAction) => {
  switch (action.type) {
    case 'SET': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

const initialState = {
  chartData: {
    datasets: [],
    labels: [],
  },
  chartOptions: {},
};

const useChart = (chartType: 'bar' | 'doughnut', canvasRef: React.RefObject<HTMLCanvasElement | null>) => {
  const [{ chart, chartData, chartOptions }, dispatch] = useReducer(chartReducer, initialState);

  useEffect(() => {
    dispatch({ type: 'SET', payload: { chartType } });
  }, [chartType]);

  useEffect(() => {
    if (!chart) {
      if (canvasRef && canvasRef.current) {
        const chartCtx = canvasRef.current.getContext('2d');
        if (chartCtx) {
          const newChart = new Chart(chartCtx, {
            type: chartType,
            data: chartData,
            options: chartOptions,
          });
          dispatch({ type: 'SET', payload: { chart: newChart } });
        }
      }
    } else {
      chart.data = chartData;
      chart.options = chartOptions;
      chart.update();
    }
  }, [canvasRef, chartData, chartOptions, chart, chartType]);

  const createChart = (initialData: ChartData, initialOptions: ChartOptions) => {
    dispatch({ type: 'SET', payload: { chartData: initialData, chartOptions: initialOptions, canvasRef } });
  };

  const updateChart = (newData: ChartData, newOptions = chartOptions) => {
    dispatch({ type: 'SET', payload: { chartData: newData, chartOptions: newOptions } });
  };

  return { updateChart, createChart, chartData, chartOptions, chart };
};

export default useChart;
