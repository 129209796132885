import React, { createContext, useReducer } from 'react';

type NotificationContextArgs = {
  state: NotificationContextState;
  dispatch: React.Dispatch<NotificationContextAction>;
};

type NotificationContextState = {
  isOpen: boolean;
  message: string;
  color: 'error' | 'success' | 'info' | 'warning' | undefined;
};

type NotificationContextAction = {
  type: string;
  payload?: any;
};

const NotificationReducer = (state: NotificationContextState, action: NotificationContextAction) => {
  switch (action.type) {
    case 'OPEN': {
      return {
        ...state,
        isOpen: true,
        message: action.payload?.message,
        color: action.payload?.color,
      };
    }
    case 'CLOSE': {
      return {
        ...state,
        isOpen: false,
        message: '',
      };
    }
    default: {
      throw new Error();
    }
  }
};

const NotificationContext = createContext({} as NotificationContextArgs);

const initialState = {
  isOpen: false,
  message: '',
  color: '',
};

export const NotificationContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(NotificationReducer, initialState);
  const value = { state, dispatch };
  NotificationContext.displayName = 'NotificationContext';
  return (
    <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>
  );
};

export default NotificationContext;
