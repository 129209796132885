import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { colors, mediaQueries as mq } from '../../lib/styles';

const useStyles = makeStyles(() => ({
  ParagraphLargeText: {
    fontFamily: "'游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'Noto Sans JP', 'ヒラギノ角ゴ ProN' , 'Hiragino Kaku Gothic ProN' , '游ゴシック' , '游ゴシック体' , YuGothic , 'Yu Gothic' , 'メイリオ' , Meiryo , 'ＭＳ ゴシック' , 'MS Gothic' , HiraKakuProN-W3 , 'TakaoExゴシック' , TakaoExGothic , 'MotoyaLCedar' , 'Droid Sans Japanese' , sans-serif",
    letterSpacing: '-1px',
    fontSize: '1.5em',
    margin: '0',
    marginBottom: '1.5em',
    fontWeight: 500,
    color: `${colors.textGray}`,

    [mq.mdOnly]: {
      fontSize: '1.2em',
    },
    [mq.smOnly]: {
      fontSize: '1.3em',
    },
  },
}));

type ParagraphLargeTextProps = {
  className?: string;
};

const ParagraphLargeText: React.FC<ParagraphLargeTextProps> = ({ children, className }) => {
  const classes = useStyles();

  return (
    <p className={clsx(classes.ParagraphLargeText, className)}>{children}</p>
  );
};

ParagraphLargeText.defaultProps = {
  className: undefined,
};

export default ParagraphLargeText;
