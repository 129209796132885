import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { mediaQueries as mq } from '../../lib/styles';

const useStyles = makeStyles(() => ({
  UnderlineText: {
    textAlign: 'center',
    marginBottom: 0,
    fontWeight: 500,
    [mq.smOnly]: {
      marginTop: 12,
    },
    [mq.mdUp]: {
      marginTop: 34,
      fontSize: 22,
    },
    '& + $UnderlineText': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  UnderlineText__line: {
    display: 'inline-block',
    background: 'linear-gradient(transparent 70%, #ffff00 70%)',
  },
}));

type UnderlineTextProps = {};

const UnderlineText: React.FC<UnderlineTextProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <p className={classes.UnderlineText}><span className={classes.UnderlineText__line}>{children}</span></p>
  );
};

export default UnderlineText;
