// 2024-03-05: 小田原市再エネポテンシャル見える化
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { mediaQueries as mq } from '../../lib/styles';
import Button from '../Button/Button';

const useStyles = makeStyles(() => ({
  Contents: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [mq.smOnly]: {
      // スマホの場合、上の要素との間に余白を設定
      margin: '5px 0 0 0',
    },
    [mq.mdUp]: {
      // タブレット、PCの場合、右端との間に余白を設定
      margin: '0 10px 0 0',
    },
  },
  Text: {
    width: '15em',
  },
  ButtonWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [mq.smOnly]: {
      // スマホの場合、横並び
      flexDirection: 'row',
    },
    [mq.mdUp]: {
      // タブレット、PCの場合、縦並び
      flexDirection: 'column',
    },
  },
  LoginButton: {
    fontSize: '1em',
    width: '12em',
    height: '2.5em',
    [mq.smOnly]: {
      // スマホの場合、上下左右に余白を設定
      margin: '5px',
    },
    [mq.mdUp]: {
      // タブレット、PCの場合、上の要素との間に余白を設定
      margin: '5px 0 0 0',
    },
  },
  Link: {
    [mq.smOnly]: {
      // スマホの場合、上下左右に余白を設定
      margin: '5px',
    },
    [mq.mdUp]: {
      // タブレット、PCの場合、上の要素との間に余白を設定
      margin: '5px 0 0 0',
    },
  },
}));

const OdawaraNavLinks: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.Contents}>
      <div className={classes.Text}>小田原市地産地消再エネ事業者</div>
      <div className={classes.ButtonWrap}>
        <Button
          className={classes.LoginButton}
          type="banner"
          to={process.env.REACT_APP_B_TO_B_SUNCLE_ODAWARA_URL}
        >
          登録事業者ログイン
        </Button>
        <a
          className={classes.Link}
          href="https://www.city.odawara.kanagawa.jp/field/envi/energy/preceding-region/p37034.html"
          target="_blank"
          rel="noreferrer noopener"
        >
          未登録の事業者はこちら
        </a>
      </div>
    </div>
  );
};

export default OdawaraNavLinks;
