import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors, mediaQueries as mq } from '../../lib/styles';

import './CssBaseline.scss';

const useStyles = makeStyles(() => ({
  '@global': {
    '*, *:before, *:after': {
      boxSizing: 'inherit',
    },
    html: {
      boxSizing: 'border-box',
      WebkitFontSmoothing: 'antialiased', // Antialiasing.
      MozOsxFontSmoothing: 'grayscale', // Antialiasing.
    },
    img: {
      display: 'inline-block',
      height: 'auto',
      maxHeight: '100%',
      alignSelf: 'center',
      flexShrink: 0,
      maxWidth: '100%',
    },
    input: {
      lineHeight: 1.5,
    },
    /* Change Autocomplete styles in Chrome*/
    'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:hover, select:-webkit-autofill:focus': {
      backgroundColor: `${colors.white} !important`,
      color: `${colors.textColor} !important`,
      boxShadow: '0 0 0 380px $white inset !important',
    },
    'button:focus': {
      outline: 'none !important',
    },
    'input[type=range]': {
      appearance: 'none', /* Hides the slider so that custom slider can be made */
      '-webkit-appearance': 'none',
      background: 'transparent', /* Otherwise white in Chrome */
    },
    'input[type=range]::-webkit-slider-thumb': {
      '-webkit-appearance': 'none',
    },
    'input[type=range]:focus': {
      outline: 'none', /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
    },
    'input[type=range]::-ms-track': {
      width: '100%',
      cursor: 'pointer',
      /* Hides the slider so custom styles can be added */
      background: 'transparent',
      borderColor: 'transparent',
      color: 'transparent',
    },
    body: {
      textRendering: 'optimizeLegibility',
      fontFamily: "'Noto Sans JP', 'ヒラギノ角ゴ ProN' , 'Hiragino Kaku Gothic ProN' , '游ゴシック' , '游ゴシック体' , YuGothic , 'Yu Gothic' , 'メイリオ' , Meiryo , 'ＭＳ ゴシック' , 'MS Gothic' , HiraKakuProN-W3 , 'TakaoExゴシック' , TakaoExGothic , 'MotoyaLCedar' , 'Droid Sans Japanese' , sans-serif",
      color: colors.textColor,
      lineHeight: 1.6,
      letterSpacing: '0.02em',
      [mq.smOnly]: {
        fontSize: 13,
      },
      [mq.mdUp]: {
        fontSize: 16,
      },
    },
    'h1, h2, h3, h4, h5, h6, p, span, a, li, button, blockquote, code, pre, th, td, label, input, textarea, legend': {
      fontStyle: 'normal',
      textRendering: 'optimizeLegibility',
      textTransform: 'none',
    },
    p: {
      marginTop: 8,
      marginBottom: 8,
    },
    a: {
      transition: 'all .1s ease',
      color: colors.orange,
      fontWeight: 'bold',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    ol: {
      listStyle: 'none',
      counterReset: 'counter',
      paddingLeft: 30,
      '& > li': {
        width: '100%',
        position: 'relative',
        '&::before': {
          counterIncrement: 'counter',
          content: 'counter(counter)"."',
          position: 'absolute',
          left: -30,
          textAlign: 'right',
          width: 24,
        },
      },
    },
    ul: {
      listStyle: 'none',
      paddingLeft: 20,
      '& > li': {
        width: '100%',
        position: 'relative',
        '&::before': {
          counterIncrement: 'counter',
          content: "'・'",
          position: 'absolute',
          left: -18,
        },
      },
    },
    'li + li': {
      marginTop: 4,
    },
    'li + ul, li + ol': {
      marginTop: 16,
      marginBottom: 16,
    },
    'input::placeholder': {
      color: 'rgba(0,0,0,.3)',
    },
  },
}));

const CssBaseline: React.FC = ({ children }) => {
  useStyles();
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default React.memo(CssBaseline);
