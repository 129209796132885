import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { colors, mediaQueries as mq } from '../../lib/styles';

const useStyles = makeStyles(() => ({
  H5Text: {
    fontFamily: "'游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'Noto Sans JP', 'ヒラギノ角ゴ ProN' , 'Hiragino Kaku Gothic ProN' , '游ゴシック' , '游ゴシック体' , YuGothic , 'Yu Gothic' , 'メイリオ' , Meiryo , 'ＭＳ ゴシック' , 'MS Gothic' , HiraKakuProN-W3 , 'TakaoExゴシック' , TakaoExGothic , 'MotoyaLCedar' , 'Droid Sans Japanese' , sans-serif",
    letterSpacing: '-1px',
    fontSize: '1em',
    margin: '0',
    marginBottom: '0.15em',
    color: `${colors.textGray}`,
    [mq.smOnly]: {
    },
    [mq.mdOnly]: {
    },
    [mq.lgOnly]: {
    },
  },
}));

type H5TextProps = {
  className?: string;
};

const H5Text: React.FC<H5TextProps> = ({ children, className }) => {
  const classes = useStyles();

  return (
    <h5 className={clsx(classes.H5Text, className)}>{children}</h5>
  );
};

H5Text.defaultProps = {
  className: undefined,
};

export default H5Text;
