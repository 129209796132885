import React, { createContext, useReducer, useEffect, useState } from 'react';
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import { QueryRegex, FindParam } from '../utils/queryString';

import defaultGeocode from '../data/defaultGeocode';
import { axiosInstance, useGetBillingCalculator, useGetSolarData, useGetIncentives } from '../hooks/useAxios';
import { Amortization, BillingCalculator, CashFlow, GenerationConfig, GenerationParameters, GeocodeResult, SolarPanelConfig, SystemCost, SystemParameters, CashFlowParameters, LoanInfo } from '../../types/custom';

import { getPriceStructureFromBillingCalculatorStructure, monthlyKwhToYen, monthlyYenToKwh } from '../utils/billing';

import { mockSystemData, adjustEfficiency, defaultParameters as generationDefaultParameters } from '../utils/generation';
import { calcDeduction } from '../utils/incentive';
import { getUpfrontCost, loanCost, defaultParameters as systemDefaultParameters } from '../utils/systemCost';
import { emptyCashFlow, estimateConsumption, calcSpecificCase, defaultParameters as cashFlowDefaultParameters } from '../utils/cashFlow';

import { updateCarbonOffset } from '../utils/ecoMetrics'; // Maybe update later whole thing is one big config.

import SIMULATION_PARAMETERS from './SimulationParameters';

type GeocodeData = {
  default?: boolean;
  prefecture: string;
  lat: number;
  lng: number;
  city: {
    [key: string]: string;
  },
  postcode: string;
  postcodeFull: string;
  completeAddress: string;
  plus_code: {
    [key: string]: string;
  },
  results: GeocodeResult[],
  status: string;
};

type SimulationContextArgs = {
  state: SimulationContextState;
  dispatch: React.Dispatch<SimulationContextAction>;
};

type Coordinates = {
  lat?: number,
  lng?: number,
};

// TODO: add configs to context.
type SimulationContextState = {
  // coordinates: Coordinates,
  // isInsideJapan: boolean | 'pending',
  shouldRefresh?: boolean,
  billCompareChartData: any, // TODO: type this
  billingCalculator?: BillingCalculator,
  coordinates?: any,
  ecoMetrics: any, // TODO: type this
  geocodeData: GeocodeData,
  hasError: boolean,
  isInsideJapan: any,
  isLoading: boolean,
  maxPanelsCount: number,
  minPanelsCount: number,
  monthlyBill: number,
  panelsCount?: number,
  paymentType: string,
  priceStructure?: number[];
  recommendedPanelsCount: number,
  selectedCashFlow?: CashFlow,
  selectedSystemCost?: SystemCost,
  yearlyConsumption: number;
  generationParameters: GenerationParameters;
  systemParameters: SystemParameters;
  cashFlowParameters: CashFlowParameters;
};

type ActionTypes = 'SET'
| 'LOADING'
| 'ERROR'
| 'VERIFY_GEOCODE';

type SimulationContextAction = {
  type: ActionTypes;
  payload?: {
    shouldRefresh?: boolean,
    billCompareChartData?: any, // TODO: type this
    billingCalculator?: BillingCalculator;
    coordinates?: Coordinates,
    ecoMetrics?: any, // TODO: type this
    geocodeData?: GeocodeData;
    isInsideJapan?: boolean | 'pending';
    maxPanelsCount?: number;
    minPanelsCount?: number;
    monthlyBill?: number;
    panelsCount?: number,
    paymentType?: string,
    priceStructure?: number[];
    recommendedPanelsCount?: number;
    selectedCashFlow?: CashFlow;
    selectedSystemCost?: SystemCost;
    yearlyConsumption?: number;
    generationParameters?: GenerationParameters;
    systemParameters?: SystemParameters;
    cashFlowParameters?: CashFlowParameters;
  };
};

const CoordinatesReducer = (state: SimulationContextState, action: SimulationContextAction) => {
  switch (action.type) {
    case 'SET': {
      if (action.payload) {
        const { coordinates, isInsideJapan, ...rest } = action.payload;
        if (coordinates && state.coordinates) {
          const { lat: currentLat, lng: currentLng } = state.coordinates;
          const { lat: newLat, lng: newLng } = coordinates;
          if (currentLat.toFixed(6) === newLat?.toFixed(6) && currentLng.toFixed(6) === newLng?.toFixed(6)) {
            // don't update the coordinates if the lat/lng are same within 6 places
            return {
              ...state,
              isLoading: false,
              hasError: false,
              ...rest,
            };
          }
        }
        return {
          ...state,
          isLoading: false,
          hasError: false,
          ...action.payload,
        };
      }
      return state;
    }
    case 'VERIFY_GEOCODE': {
      if (action.payload) {
        const { coordinates, isInsideJapan } = action.payload;
        return {
          ...state,
          isLoading: true,
          hasError: false,
          coordinates,
          isInsideJapan,
        };
      }
      return state;
    }
    case 'LOADING': {
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    }
    case 'ERROR': {
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    }
    default: {
      throw new Error();
    }
  }
};

const thtMonthlyPayment = (panelConfig: SolarPanelConfig, averageGen : number) => {
  const amount = (((panelConfig.panelsCount * averageGen * 160000 + 40000) / 0.84) + 20000) * 0.01;
  return (500 - (amount % 500)) + amount;
};

const thtYearlyPayments = (monthlyPayment: number, months: number) => {
  const totalCost = monthlyPayment * months;
  const firstAmort: Amortization = {
    payment: 0,
    principal: 0,
    interest: 0,
    totalInterest: 0,
    balance: totalCost,
  };
  const amortizations: Amortization[] = [firstAmort];
  const monthlyPR: number = 0;
  let balance: number = totalCost;
  let totalInterest = 0;
  for (let monthCount = 1; monthCount <= months; monthCount++) {
    const interest: number = balance * monthlyPR;
    totalInterest += interest;
    const payment: number = Math.min(monthlyPayment, balance + interest); // Adjust for final payment
    const principal: number = Math.min(monthlyPayment - interest, balance); // Adjust for final payment
    balance -= principal;
    if (monthCount % 12 === 0) {
      const amort: Amortization = {
        payment,
        principal,
        interest,
        totalInterest,
        balance: Math.max(balance, 0),
      };
      amortizations.push(amort);
    }
  }
  return amortizations;
};

const SimulationContext = createContext({} as SimulationContextArgs);

const initialState: SimulationContextState = {
  shouldRefresh: true,
  isLoading: false,
  hasError: false,
  isInsideJapan: 'pending',
  geocodeData: defaultGeocode,
  recommendedPanelsCount: 0,
  minPanelsCount: 0,
  maxPanelsCount: 0,
  monthlyBill: 10000,
  ecoMetrics: {
    carsRemovedPerYear: 0,
    treesSaved: 0,
    volumeInBottlesFilled: 0,
    cellPhoneCharges: 0,
    refrigeratorHours: 0,
    riceCooking: 0,
    carbonOffsetKgPerDay: 0,
  },
  billCompareChartData: {
    max: 100000,
    finalRevenue: 0,
    fitRevenue: [0],
    electricSavings: [0],
    totalRevenue: [0],
    breakevenOrPayments: [0],
    initialPaymentBoxOffsetY: 0,
    breakevenBoxOffsetX: 0,
  },
  paymentType: 'buy',
  yearlyConsumption: 0,
  generationParameters: generationDefaultParameters,
  systemParameters: systemDefaultParameters,
  cashFlowParameters: cashFlowDefaultParameters,
};

export const SimulationContextProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [{ results: billingCalculator }, makeBillingCalculatorRequest] = useGetBillingCalculator();
  const [{ results: solarData }, makeSolarDataRequest] = useGetSolarData();
  const [{ results: incentives }, makeIncentivesRequest] = useGetIncentives();
  const [state, dispatch] = useReducer(CoordinatesReducer, initialState);
  const [cashFlowParameters, setCashFlowParameters] = useState<CashFlowParameters>(cashFlowDefaultParameters);
  const [generationConfig, setGenerationConfig] = useState<GenerationConfig>();
  const [solarPanelConfigs, setSolarPanelConfigs] = useState<SolarPanelConfig[]>();
  const [systemCostCalcs, setSystemCostCalcs] = useState<SystemCost[]>();
  const [allCashFlows, setAllCashFlows] = useState<CashFlow[]>();
  const [panels, setPanels] = useState<number | undefined>();
  const value = { state, dispatch };
  SimulationContext.displayName = 'SimulationContext';

  const setSystemCost = async (panelsCount: number) => {
    // console.log("setSystemCost")
    if (systemCostCalcs) {
      // console.log("systemcostcalcs", systemCostCalcs)
      await systemCostCalcs.forEach((systemCost) => {
        if (systemCost.panelsCount === panelsCount) {
          // Tells are info widget how environmentally awesome they are.
          const newEcoMetrics = updateCarbonOffset(systemCost.yearlyEnergyAcKwh);
          // Tells everyone else the cost of being awesome.
          // console.log("setting selectedsystemcost", systemCost)
          dispatch({ type: 'SET', payload: { selectedSystemCost: systemCost, ecoMetrics: newEcoMetrics } });
        }
      });
    }
  };

  useEffect(() => {
    if (location.search) {
      const lat = FindParam(location.search, QueryRegex('lat'));
      const lng = FindParam(location.search, QueryRegex('lng'));

      if (lat && lng) {
        dispatch({ type: 'VERIFY_GEOCODE', payload: { coordinates: { lat: Number(lat), lng: Number(lng) }, isInsideJapan: 'pending' } });
      } else {
        dispatch({ type: 'SET', payload: { coordinates: { lat: defaultGeocode.lat, lng: defaultGeocode.lng }, isInsideJapan: 'pending' } });
      }
    } else {
      dispatch({ type: 'SET', payload: { coordinates: { lat: defaultGeocode.lat, lng: defaultGeocode.lng }, isInsideJapan: 'pending' } });
    }
  }, []);

  useEffect(() => {
    if (location.search && location.pathname.includes('search')) {
      const lat = FindParam(location.search, QueryRegex('lat'));
      const lng = FindParam(location.search, QueryRegex('lng'));
      const addr = FindParam(location.search, QueryRegex('addr'));
      if (lat && lng && !addr) {
        if (!state.coordinates || (Number(lat) !== state.coordinates.lat && Number(lng) !== state.coordinates.lng)) {
          dispatch({ type: 'VERIFY_GEOCODE', payload: { coordinates: { lat: Number(lat), lng: Number(lng) }, isInsideJapan: 'pending' } });
        }
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    // console.log("state.isInsideJapan, state.coordinates", state.isInsideJapan, state.coordinates)
    // TODO: show error message when not inside Japan
    const verifyIsInsideJapan = async () => {
      if (state.coordinates && state.isInsideJapan === 'pending') {
        dispatch({ type: 'LOADING' });
        const response = await axiosInstance.get(`/geocode?lat=${state.coordinates.lat.toFixed(6)}&lng=${state.coordinates.lng.toFixed(6)}`);
        if (response.status === 200) {
          dispatch({ type: 'SET', payload: { isInsideJapan: response.data.isInsideJapan, geocodeData: response.data.geocodeData } });
          // dispatch({ type: 'LOADING' });
          makeSolarDataRequest({ lat: state.coordinates.lat, lng: state.coordinates.lng });
          const { prefecture: newPrefecture, city: newCity, postcode: newPostcode } = response.data.geocodeData;
          const { prefecture: currentPrefecture, city: currentCity, postcode: currentPostcode } = state.geocodeData;
          if ((newPrefecture !== currentPrefecture || JSON.stringify(newCity) !== JSON.stringify(currentCity)) || !systemCostCalcs) {
            const cityFullName = newCity.aal2 ? newCity.aal2 + newCity.city : newCity.city;
            // TODO make sure returns error when incentive does not exist.
            makeIncentivesRequest(newPrefecture, cityFullName);
          }
          if (!billingCalculator) {
            makeBillingCalculatorRequest(newPostcode);
          } else if (state.paymentType !== 'timebased' && newPostcode !== currentPostcode) {
            makeBillingCalculatorRequest(newPostcode);
          }
        } else {
          dispatch({ type: 'ERROR' });
        }
      }
    };

    if (state.coordinates && (location.pathname.includes('search') || location.pathname.includes('sim'))) {
      const newQs = queryString.stringify({
        ...queryString.parse(location.search),
        lat: state.coordinates.lat,
        lng: state.coordinates.lng,
        addr: undefined,
      });
      navigate(`${location.pathname}?${newQs}`, { replace: true });
    }

    verifyIsInsideJapan();
  }, [state.isInsideJapan, state.coordinates]);

  useEffect(() => {
    // console.log("solarData, state.generationParameters",solarData, state.generationParameters)
    if (solarData) {
      let newSolarPanelConfigs: SolarPanelConfig[];
      let newGenerationConfig: GenerationConfig;
      if (solarData.isSimulation) { // not real data from the Google sunroof API
        newSolarPanelConfigs = mockSystemData(state.generationParameters);
        newGenerationConfig = {
          center: state.coordinates,
          isSimulation: true,
          placeId: '',
          sunshineHours: state.generationParameters.defaultSunshineHours,
          rooftopAreaMeters2: solarData.areaMeters2, // TODO: Find a way to accurately determine roof size
          maxPanelsCount: Math.min(newSolarPanelConfigs[newSolarPanelConfigs.length - 1].panelsCount, SIMULATION_PARAMETERS.MAX_PANELS_COUNT),
        };
      } else {
        newSolarPanelConfigs = [...solarData.solarPotential.solarPanelConfigs].map(
          (config) => {
            return adjustEfficiency(config, state.generationParameters);
          },
        );
        newGenerationConfig = {
          center: state.coordinates,
          isSimulation: false,
          placeId: solarData.name.split('/')[1],
          sunshineHours: solarData.solarPotential.maxSunshineHoursPerYear,
          rooftopAreaMeters2: solarData.solarPotential.wholeRoofStats.areaMeters2,
          maxPanelsCount: Math.min(solarData.solarPotential.maxArrayPanelsCount, SIMULATION_PARAMETERS.MAX_PANELS_COUNT),
        };
      }
      // console.log("Checking new solar panel configs", newSolarPanelConfigs)
      setSolarPanelConfigs(newSolarPanelConfigs);
      setGenerationConfig(newGenerationConfig);
    }
  }, [solarData, state.generationParameters]);

  useEffect(() => {
    // console.log("solarPanelConfigs, incentives, state.generationParameters.averageGenerationPerPanel, state.systemParameters.averageCostPerPanel",solarPanelConfigs, incentives, state.generationParameters.averageGenerationPerPanel, state.systemParameters.averageCostPerPanel)
    if (incentives && solarPanelConfigs) {
      let newSystemCostCalcs = solarPanelConfigs.map((panelConfig) => {
        const newPanelCount = panelConfig.panelsCount;
        let upfrontCost = 0;
        let loanDetails: LoanInfo;
        if (state.paymentType === 'buy' || state.paymentType === 'loan') {
          upfrontCost = getUpfrontCost(newPanelCount, state.systemParameters);
          loanDetails = loanCost(upfrontCost, state.systemParameters);
        } else {
          // Probably cannot do it this way because amortization will break. Need to dumb down the components to just render passed values or replace loanCost() altogether
          const monthlyPayment = thtMonthlyPayment(panelConfig, state.generationParameters.averageGenerationPerPanel);
          const loanLength = 12 * 10;
          loanDetails = {
            months: loanLength,
            monthlyPayment,
            principal: 0,
            totalInterest: 0,
            yearlyAmortizationSchedule: thtYearlyPayments(monthlyPayment, loanLength),
          };
        }
        // TODO: Do incentives for total cost apply to the enekari payment model?
        const { totalDeduction, deductionArray } = calcDeduction(incentives, panelConfig, upfrontCost, state.generationParameters.averageGenerationPerPanel);
        return {
          panelsCount: newPanelCount,
          yearlyEnergyAcKwh: panelConfig.yearlyEnergyAcKwhAdjusted,
          upfrontCost,
          maintenanceCost: 0,
          loan: loanDetails,
          incentiveDeduction: totalDeduction,
          deductionArray: deductionArray.map((deduction: any, index: number) => ({
            name: incentives[index].name,
            url: incentives[index].url,
            deduction, // TODO: type this
          })),
        };
      });
      dispatch({ type: 'SET', payload: { selectedCashFlow: undefined, selectedSystemCost: undefined, shouldRefresh: true } });
      setSystemCostCalcs(newSystemCostCalcs);
    }
  }, [solarPanelConfigs, incentives, state.generationParameters.averageGenerationPerPanel, state.systemParameters.averageCostPerPanel]);

  /**
   * Handle System Size Slider
   */
  useEffect(() => {
    if (panels !== undefined && allCashFlows) {
      let cf = allCashFlows.filter((c) => c.panelsCount === panels);
      if (cf.length === 1) {
        //電気料金更新時、以下のコメントアウトを解除
        //console.log(cf[0]);
        setSystemCost(cf[0].panelsCount).then(() => {
          dispatch({ type: 'SET', payload: { selectedCashFlow: cf[0] } });
        });
      }
    }
  }, [panels, allCashFlows]);

  useEffect(() => {
    setPanels(state.panelsCount || 10);
  }, [state.panelsCount]);

  useEffect(() => {
    // 指定したパネル枚数のキャッシュフローを取得する。
    function getCashFlow(cashflows: CashFlow[], panelsCount: number) {
      const cashFlow = cashflows.find((cf) => {
        return cf.panelsCount === panelsCount;
      }) ?? cashflows[0];
      return cashFlow;
    }
    async function findBestConfig(cashflows: CashFlow[], isSimulation: boolean | undefined) {
      // console.log("findBestConfig")
      // 2024-05-13: ソーラーデータのパネル容量変更に伴う対応
      // 発電量が0.25kWから0.4kWに変わったことにより、
      // サンクル側で定義している最小のシステム容量（2kW）を計算するためのパネル枚数は5枚となった。
      // 最小のキャッシュフローを配列の0番目から取得すると、
      // パネル枚数が4のソーラーデータを取得してしまうケースがある。
      // そのため、最小のパネル枚数を指定してキャッシュフローを取得するように変更した。
      //
      // 2024-05-15: ソーラーデータのパネル容量変更に伴う対応
      // 最小パネル枚数を5枚に設定した状態で、ソーラーデータで4枚の結果のみが返された場合、
      // システム容量のスライダーが壊れてしまう事象が発生した。
      // すぐの解消が難しいため、システム容量の最小値を1.6kW（4枚）とした。
      // そのため、このコードは変更前の状態に戻しても良いが、悪影響はないため、残しておく。
      const minCashFlow = getCashFlow(cashflows, SIMULATION_PARAMETERS.MIN_PANELS_COUNT);
      let bestBuy: CashFlow = cashflows.length > 1 ? minCashFlow : emptyCashFlow;

      // 2024-03-06: シャープ様からの依頼による変更対応
      // ソーラーデータを取得できない地点のシステム容量の初期値を4.75kWから5.00kWに変更
      // 2024-05-09: ソーラーデータのパネル容量変更に伴う対応
      // パネル1枚あたりの発電量が0.25kWから0.4kWに変更になったことで、システム容量の初期値として5.00kWの指定が不可となったため、近い値の4.80kWに変更。
      const stoppingPoint = isSimulation ? SIMULATION_PARAMETERS.DEFAULT_PANELS_COUNT : state.cashFlowParameters.maxRecommendedPanelsCount; // 4.80 kW simulation recommendation
      const validCashFlows = cashflows.filter((c) => c.panelsCount <= stoppingPoint && c.panelsCount >= state.cashFlowParameters.minRecommendedPanelsCount);

      if (isSimulation) {
        return validCashFlows[validCashFlows.length - 1];
      }

      await validCashFlows.forEach((cashFlow) => {
        // TODO: if totalSavingsBuy does not increase significantly return;
        if (bestBuy.totalSavingsBuy < cashFlow.totalSavingsBuy) {
          bestBuy = cashFlow;
        }
      });
      // console.log(bestBuy)
      return bestBuy;
    }
    // console.log("allCashFlows", allCashFlows, generationConfig)
    // Expected behavior: On page load recommend a starting system size.
    if (allCashFlows && allCashFlows.length > 0 && generationConfig && state.shouldRefresh) {
      findBestConfig(allCashFlows, generationConfig.isSimulation).then((r) => {
        setPanels(r.panelsCount);
        dispatch({ type: 'SET', payload: { panelsCount: r.panelsCount, recommendedPanelsCount: r.panelsCount, shouldRefresh: false } });
      }).catch((e) => {
        // console.log("error finding best configuration")
      });
    }
  }, [allCashFlows]);

  useEffect(() => {
    // console.log("state.monthlyBill, state.billingCalculator, systemCostCalcs, state.cashFlowParameters",state.monthlyBill, state.billingCalculator, systemCostCalcs, state.cashFlowParameters)
    if (systemCostCalcs && state.billingCalculator) {
      // typescript complains that state.billingCalculator could be undefined
      // if we use it directly in calcSpecificCase, even though the if should protect for that
      const billCalc = state.billingCalculator;
      const newCashFlows = systemCostCalcs.map((sysCostInfo) => {
        // console.log(state.yearlyConsumption)
        return calcSpecificCase(sysCostInfo, state.yearlyConsumption, billCalc, state.monthlyBill, state.cashFlowParameters);
      });
      // console.log("new cash flows", newCashFlows)
      setAllCashFlows(newCashFlows);
    }
  }, [state.yearlyConsumption, state.billingCalculator, systemCostCalcs, state.cashFlowParameters]);

  useEffect(() => {
    // console.log("state.monthlyBill", state.monthlyBill, billingCalculator)
    if (billingCalculator) {
      const yearlyConsumption = monthlyYenToKwh(billingCalculator, state.monthlyBill) * 12;
      const payload = {
        yearlyConsumption,
      };
      dispatch({ type: 'SET', payload });
    }
  }, [state.monthlyBill, billingCalculator]);

  useEffect(() => {
    // console.log("systemCostCalcs", systemCostCalcs)
    // if (systemCostCalcs && billingCalculator) {
    if (systemCostCalcs) {
      const newMaxPanelsCount = systemCostCalcs[systemCostCalcs.length - 1].panelsCount < SIMULATION_PARAMETERS.MAX_PANELS_COUNT
        ? systemCostCalcs[systemCostCalcs.length - 1].panelsCount
        : SIMULATION_PARAMETERS.MAX_PANELS_COUNT;
      const payload = {
        // 2024-03-14: シャープ様からの依頼による変更対応
        // ソーラーデータを取得できた場合（最小値1kW）と
        // ソーラーデータを取得できなかった場合（最小値2kW）とで
        // システム容量の最小値が異なっていたが、2kWに統一した。
        minPanelsCount: Math.max(systemCostCalcs[0].panelsCount, SIMULATION_PARAMETERS.MIN_PANELS_COUNT),
        maxPanelsCount: newMaxPanelsCount,
        // billingCalculator,
      };
      dispatch({ type: 'SET', payload });
    }
  }, [systemCostCalcs]);

  useEffect(() => {
    // console.log("billingCalculator", billingCalculator)
    if (billingCalculator) {
      let priceStructure;
      if (billingCalculator.type !== 'timebased') {
        priceStructure = getPriceStructureFromBillingCalculatorStructure(billingCalculator.structure, billingCalculator.baseCharge);
      }
      // const newMonthlyBill = monthlyKwhToYen(billingCalculator, state.yearlyConsumption / 12);
      const payload = {
        priceStructure,
        monthlyBill: 10000,
        billingCalculator,
        yearlyConsumption: monthlyYenToKwh(billingCalculator, 10000) * 12,
      };
      dispatch({ type: 'SET', payload });
    }
  }, [billingCalculator]);

  useEffect(() => {
    // console.log("state.billingCalculator", state.billingCalculator)
    if (state.billingCalculator && (state.billingCalculator !== billingCalculator)) {
      let priceStructure;
      if (state.billingCalculator.type !== 'timebased') {
        priceStructure = getPriceStructureFromBillingCalculatorStructure(state.billingCalculator.structure, state.billingCalculator.baseCharge);
      }
      const payload = {
        priceStructure,
        monthlyBill: 10000,
        yearlyConsumption: monthlyYenToKwh(state.billingCalculator, 10000) * 12,
      };
      dispatch({ type: 'SET', payload });
    }
  }, [state.billingCalculator]);

  useEffect(() => {
    // console.log("state.selectedCashFlow state.paymentType", state.selectedCashFlow, state.paymentType)
    if (state.selectedCashFlow && state.selectedCashFlow.cashFlowSchedule && state.selectedSystemCost) {
      // Pick a max that will line up with revenue
      const newMax = Math.ceil((state.selectedCashFlow.totalFitRevenue + state.selectedCashFlow.totalElectricSavings) / 500000) * 50000;
      // const newTotalRevenue = [...state.billCompareChartData.totalRevenue];
      // const newBreakevenOrPayments = [...state.billCompareChartData.breakevenOrPayments];
      // const newFitRevenue = [...state.billCompareChartData.fitRevenue];
      // const newElectricSavings = [...state.billCompareChartData.electricSavings];
      // 20年間の発電収支（各年までの合計）
      // 例）1年目の発電収支が1万円、2年目の発電収支が2万円の場合、2年目の値は合計の3万円となる。
      const newTotalRevenue: number[] = [];
      // 20年間の設置費用目安（各年の値（一括の場合、毎年同じ値））
      let newBreakevenOrPayments: number[] = [];
      // 20年間の売電収益（各年の値）
      const newFitRevenue: number[] = [];
      // 20年間の節電収益（各年の値）
      const newElectricSavings: number[] = [];
      // 設置費用のお支払い金額
      let paybackAmount: number | undefined;
      // 設置費用の回収期間
      let paybackYear = state.selectedCashFlow.paybackPeriod;
      // 20年目の「節約できる電気代」（オレンジの線の右上）
      let newFinalRevenue = state.billCompareChartData.finalRevenue;
      // cashFlowScheduleは20年間の発電収支の情報を保持した配列
      state.selectedCashFlow.cashFlowSchedule.forEach((cfs) => {
        // newTotalRevenue.shift();
        newTotalRevenue.push(Math.round(cfs.totalFitAndElectricToDate));
        // if (state.selectedCashFlow?.paybackPeriod === cfs.year) {
        //   newBreakevenOrPayments.fill(cfs.totalFitAndElectricToDate);
        // }
        if (state.selectedCashFlow?.paybackPeriod === cfs.year) {
          // この条件の中で「設置費用のお支払い金額」を設定する理由は不明。

          // 「設置費用のお支払い金額」に「設置費用目安」を設定
          paybackAmount = state.selectedSystemCost?.upfrontCost;

          // 2023-12-11: 「設置費用の回収期間」とグラフ「20年間の発電収支（予測値）」の損益分岐点の不一致を修正。
          //
          // グラフの「節約できる電気代」と「設置費用のお支払い金額」の
          // 2つの線が交わる位置が「設置費用の回収期間」と一致すべきだが、
          // グラフでは「設置費用のお支払い金額」から「補助金」を差し引いていなかったため、不一致となっていた。
          // そのため、支払い方法が一括の場合、「設置費用のお支払い金額」から「補助金」を差し引く。
          // 支払い方法がローンの場合は「設置費用の回収期間」が画面に表示されないため対応不要。
          if (paybackAmount && state.selectedSystemCost?.incentiveDeduction) {
            // 「設置費用のお支払い金額」から「補助金」を差し引く
            paybackAmount -= state.selectedSystemCost.incentiveDeduction;
          }
        }
        // newFitRevenue.shift();
        newFitRevenue.push(Math.round(cfs.fitRevenue));
        // newElectricSavings.shift();
        newElectricSavings.push(Math.round(cfs.yearlyElectricSavings));
        newFinalRevenue = cfs.totalFitAndElectricToDate;
      });
      // 20年間の「設置費用のお支払い金額」の配列を作成
      // 支払い方法が一括の場合、1年目のみの支払いとなるため、20年間同じ値の配列になる。
      newBreakevenOrPayments = Array(newElectricSavings.length).fill(paybackAmount);
      // Create Loan Case
      if (state.paymentType !== 'buy') {
        // 支払い方法がローンの場合
        const YAS = state.selectedSystemCost.loan.yearlyAmortizationSchedule;
        const monthlyLoanPayment = state.selectedSystemCost.loan.monthlyPayment;
        let i = 1;
        for (i; i < YAS.length; i++) {
          newBreakevenOrPayments[i - 1] = Math.round(monthlyLoanPayment * 12 * i);
        }
        newTotalRevenue.slice(i - 1).forEach((rev) => {
          if (state.selectedSystemCost) {
            newBreakevenOrPayments[i - 1] = Math.round(state.selectedSystemCost.loan.totalInterest + state.selectedSystemCost.upfrontCost);
            i += 1;
          }
        });
        paybackYear = 15;
      }
      const newBillCompareChartData = {
        max: newMax,
        finalRevenue: newFinalRevenue,
        fitRevenue: newFitRevenue,
        electricSavings: newElectricSavings,
        totalRevenue: newTotalRevenue,
        breakevenOrPayments: newBreakevenOrPayments,
        initialPaymentBoxOffsetY: 200 * (Math.abs(newBreakevenOrPayments[19] - newFinalRevenue) / newFinalRevenue) - 25,
        breakevenBoxOffsetX: 14 + ((paybackYear - 1) / 19) * 74,
      };
      dispatch({ type: 'SET', payload: { billCompareChartData: newBillCompareChartData } });
    }
  }, [state.selectedCashFlow, state.paymentType]);

  return (
    <SimulationContext.Provider value={value}>{children}</SimulationContext.Provider>
  );
};

export const withSimulationContext = (Component: React.FC) => {
  return () => <SimulationContextProvider><Component /></SimulationContextProvider>;
};

export default SimulationContext;
