// 2024-09: 福岡市向けSuncleTOP開発

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors, mediaQueries as mq } from '../../../../../lib/styles';
import { PrivacyModal } from '../../../../../components/Footer/FooterNav/index';
import FlexRow from '../../../../../components/Flex/FlexRow';
import PageTopButton from '../../../../../components/PageTopButton/PageTopButton';

const useStyles = makeStyles(() => ({
  '@global': {
    // CssBaseline.tsxで定義されている設定をクリア
    body: {
      fontSize: 'initial',
    },
  },
  fukuokaFooter: {
    maxWidth: '1360px',
    margin: '0 auto',
    display: 'flex',
    fontSize: '0.875rem',
    [mq.smOnly]: {
      flexDirection: 'column-reverse',
      justifyContent: 'start',
      padding: '0 20px',
    },
    [mq.mdUp]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '0 40px',
    },
  },
  fukuokaFooter__flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fukuokaFooter__term: {
    color: colors.purple,
    fontWeight: 'bold',
    textDecoration: 'none',
  },
  fukuokaFooter__privacyPolicy: {
    display: 'inline-block',
    // PrivacyModalの設定をCssBaselineの設定で上書き
    fontFamily: "'Noto Sans JP', 'ヒラギノ角ゴ ProN' , 'Hiragino Kaku Gothic ProN' , '游ゴシック' , '游ゴシック体' , YuGothic , 'Yu Gothic' , 'メイリオ' , Meiryo , 'ＭＳ ゴシック' , 'MS Gothic' , HiraKakuProN-W3 , 'TakaoExゴシック' , TakaoExGothic , 'MotoyaLCedar' , 'Droid Sans Japanese' , sans-serif",
    // PrivacyModalの設定を上書き
    fontSize: '0.875rem',
    color: colors.purple,
    fontWeight: 'bold',
    // PrivacyModalで設定されているmarginBottomによるレイアウト崩れを調整
    marginTop: '9px',
  },
  fukuokaFooter__linkSeparator: {
    color: colors.purple,
    fontWeight: 'bold',
    marginLeft: '16px',
    [mq.smOnly]: {
      marginRight: '16px',
    },
    [mq.mdUp]: {
      marginRight: 0,
    },
  },
}));

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={`${classes.fukuokaFooter}`}>
      <PageTopButton color="green" />
      <div>
        <p>
          東京電力ホールディングス株式会社
          <br />
          © Tokyo Electric Power Company Holdings, Inc.
        </p>
      </div>
      <div>
        <FlexRow className={classes.fukuokaFooter__flexRow}>
          <a
            href="/term"
            className={classes.fukuokaFooter__term}
          >
            サンクル利用規約
          </a>
          <span className={classes.fukuokaFooter__linkSeparator}>|</span>
          <PrivacyModal>
            <span className={classes.fukuokaFooter__privacyPolicy}>
              プライバシーポリシー
            </span>
          </PrivacyModal>
        </FlexRow>
      </div>
    </div>
  );
};

export default React.memo(Footer);
