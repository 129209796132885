import React from 'react';
import { Helmet } from 'react-helmet-async';

type MetaProps = {
  title: string;
  description: string;
  keywords: string;
  url: string;
};

const Meta: React.FC<MetaProps> = ({ title, description, keywords, url }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="og:title" property="og:title" content={title} />
      <meta name="og:url" property="og:url" content={`https://suncle.jp/${url}`} />
    </Helmet>
  );
};

export default Meta;
