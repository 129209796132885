import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MemoizeHelper from '../MemoizeHelper';
import { mediaQueries as mq } from '../../lib/styles';

const useStyles = makeStyles(() => ({
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    [mq.smOnly]: {
      flexWrap: 'wrap',
    },
    [mq.mdUp]: {
      '& > div': {
        padding: '0 16px',
      },
      '& > :first-child': {
        paddingLeft: 0,
      },
      '& > :last-child': {
        paddingRight: 0,
      },
    },
  },
}));

type FlexRowProps = {
  className?: string;
};

const FlexRow: React.FC<FlexRowProps> = ({ children, className }) => {
  const classes = useStyles();

  return <div className={clsx(classes.flexRow, className && className)}>{children}</div>;
};

FlexRow.defaultProps = {
  className: undefined,
};

export default MemoizeHelper<FlexRowProps>(FlexRow);
