import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { colors, mediaQueries as mq } from '../../lib/styles';

const useStyles = makeStyles(() => ({
  Layout: {
    width: '100%',
    backgroundColor: colors.white,
    overflowX: 'hidden',
    '&:nth-child(even)': {
      backgroundColor: colors.bgBlue,
    },
  },
  Layout__inner: {
    maxWidth: 1040,
    margin: '0 auto',
    [mq.smOnly]: {
      padding: '32px 16px',
    },
    [mq.mdOnly]: {
      padding: '64px 24px',
    },
    [mq.lgOnly]: {
      padding: '64px 0',
    },
    '& > *:first-child': {
      marginTop: 0,
    },
    '& > *:last-child': {
      marginBottom: 0,
    },
  },
  Layout__innerFull: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: '0',
    '& > *:first-child': {
      marginTop: 0,
    },
    '& > *:last-child': {
      marginBottom: 0,
    },
  },
  'Layout--pink': {
    '&:nth-child(even)': {
      backgroundColor: colors.bgPink,
    },
  },
  'Layout--gray': {
    '&:nth-child(even)': {
      backgroundColor: colors.bgGray,
    },
  },
}));

type BackgroundColors = 'Layout--pink' | 'Layout--gray';

type LayoutProps = {
  backgroundColor?: 'pink' | 'gray',
  fullWidth?: boolean,
};

const Layout: React.FC<LayoutProps> = ({ children, backgroundColor, fullWidth = false }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.Layout, backgroundColor && classes[`Layout--${backgroundColor}` as BackgroundColors])}>
      <div className={clsx({
        [classes.Layout__inner]: true,
        [classes.Layout__innerFull]: fullWidth,
      })}
      >
        {children}
      </div>
    </div>
  );
};

Layout.defaultProps = {
  backgroundColor: undefined,
  fullWidth: false,
};

export default Layout;
