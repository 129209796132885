import Snippets from './Snippets';

const TagManager = {
  // eslint-disable-next-line
  dataScript: function (dataLayer) {
    if (typeof window !== 'undefined') {
      const script = document.createElement('script');
      script.innerHTML = dataLayer;
      return script;
    }
  },
  gtm: function (args) {
    const snippets = Snippets.tags(args);

    // eslint-disable-next-line
    const noScript = () => {
      if (typeof window !== 'undefined') {
        const noscript = document.createElement('noscript');
        noscript.innerHTML = snippets.iframe;
        return noscript;
      }
    };

    // eslint-disable-next-line
    const script = () => {
      if (typeof window !== 'undefined') {
        const scriptEl = document.createElement('script');
        scriptEl.innerHTML = snippets.script;
        return scriptEl;
      }
    };

    const dataScript = this.dataScript(snippets.dataLayerVar);

    return {
      noScript,
      script,
      dataScript,
    };
  },
  initialize: function ({ gtmId, events = {}, dataLayer = undefined, dataLayerName = 'dataLayer', auth = '', preview = '' }) {
    if (typeof window !== 'undefined') {
      const nonceEl = document.head.querySelector('[nonce]');
      const nonce = nonceEl.nonce || nonceEl.getAttribute('nonce') || '';
      const gtm = this.gtm({
        id: gtmId,
        events: events,
        dataLayer: dataLayer,
        dataLayerName: dataLayerName,
        auth,
        preview,
        nonce,
      });
      const gtme = gtm.script();
      const gtmns = gtm.noScript();
      const ds = gtm.dataScript;
      gtme.setAttribute('nonce', nonce);
      gtmns.setAttribute('nonce', nonce);
      ds.setAttribute('nonce', nonce);

      if (dataLayer) document.head.appendChild(ds);
      document.head.insertBefore(gtme, document.head.childNodes[0]);
      document.body.insertBefore(gtmns, document.body.childNodes[0]);
    }
  },
  // eslint-disable-next-line
  dataLayer: function ({ dataLayer, dataLayerName = 'dataLayer' }) {
    if (typeof window !== 'undefined') {
      if (window[dataLayerName]) return window[dataLayerName].push(dataLayer);
      const snippets = Snippets.dataLayer(dataLayer, dataLayerName);
      const dataScript = this.dataScript(snippets);
      document.head.insertBefore(dataScript, document.head.childNodes[0]);
    }
  },
};

export default TagManager;
