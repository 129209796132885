import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { mediaQueries as mq } from '../../lib/styles';

const useStyles = makeStyles(() => ({
  LeadText: {
    fontWeight: 'bold',
    textAlign: 'center',
    [mq.smOnly]: {
      fontSize: 16,
    },
    [mq.mdUp]: {
      fontSize: 20,
    },
    '& + $LeadText': {
      [mq.smOnly]: {
        marginTop: 16,
      },
      [mq.mdOnly]: {
        marginTop: 24,
      },
      [mq.lgOnly]: {
        marginTop: 32,
      },
    },
  },
}));

type LeadTextProps = {};

const LeadText: React.FC<LeadTextProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <p className={classes.LeadText}>{children}</p>
  );
};

export default LeadText;
