import { SolarPanelConfig, GenerationParameters } from '../../types/custom';
import SIMULATION_PARAMETERS from '../context/SimulationParameters';

export const defaultParameters = {
  defaultSunshineHours: 1250,
  baseEfficiency: 15.3 / 100,
  japanEfficiency: 17.9 / 100,
  inverterEfficiency: 96.5 / 100,
  averageGenerationPerPanel: SIMULATION_PARAMETERS.POWER_GENERATION_PER_PANEL,
  scalePotential: 100 / 100,
};

export const adjustEfficiency = (solarConfig: SolarPanelConfig, parameters: GenerationParameters = defaultParameters) => {
  const { baseEfficiency, japanEfficiency, inverterEfficiency } = parameters;

  const scale = parameters?.scalePotential || 1;
  let base = solarConfig.yearlyEnergyDcKwh * scale;
  solarConfig.yearlyEnergyDcKwhAdjusted = base * (japanEfficiency / baseEfficiency);
  solarConfig.yearlyEnergyAcKwhAdjusted = solarConfig.yearlyEnergyDcKwhAdjusted * inverterEfficiency;

  solarConfig.roofSegmentSummaries.forEach((rs) => {
    base = rs.yearlyEnergyDcKwh * scale;
    rs.yearlyEnergyDcKwhAdjusted = base * (japanEfficiency / baseEfficiency);
    rs.yearlyEnergyAcKwhAdjusted = rs.yearlyEnergyDcKwhAdjusted * inverterEfficiency;
  });
  return solarConfig;
};

export const mockSystemData = (parameters: GenerationParameters = defaultParameters): SolarPanelConfig[] => {
  const { inverterEfficiency, averageGenerationPerPanel, defaultSunshineHours } = parameters;
  const solarConfigs: SolarPanelConfig[] = [];
  for (let panelsCount = SIMULATION_PARAMETERS.MIN_PANELS_COUNT; panelsCount <= SIMULATION_PARAMETERS.MAX_PANELS_COUNT; panelsCount++) {
    const yearlyEnergyDcKwh: number = panelsCount * averageGenerationPerPanel * defaultSunshineHours;
    solarConfigs.push({
      panelsCount,
      yearlyEnergyDcKwh,
      yearlyEnergyDcKwhAdjusted: yearlyEnergyDcKwh,
      yearlyEnergyAcKwhAdjusted: yearlyEnergyDcKwh * inverterEfficiency,
      roofSegmentSummaries: [
        {
          pitchDegrees: 0,
          azimuthDegrees: 0,
          panelsCount,
          yearlyEnergyDcKwh,
          yearlyEnergyDcKwhAdjusted: yearlyEnergyDcKwh,
          yearlyEnergyAcKwhAdjusted: yearlyEnergyDcKwh * inverterEfficiency,
        },
      ],
    });
  }
  return solarConfigs;
};
