import { BillingCalculator, Structure } from '../../types/custom';

/**
 * @description Uses the power consumption per month to return the monthly bill based on tepco's calculation
 * @param billingCalculator
 * @param {number} powerConsumptionPerMonthInKwh power consumption in kwh
 * @return {number} Return the monthly bill in YEN
 */
const standardKwhToYen = (billingCalculator: BillingCalculator, powerConsumptionPerMonthInKwh: number): number => {
  let cost = billingCalculator.baseCharge;
  if (powerConsumptionPerMonthInKwh === 0) {
    return cost;
  }

  for (const structure of billingCalculator.structure) {
    const structureDifference = structure.to
      ? Math.min(powerConsumptionPerMonthInKwh, structure.to) - structure.from
      : powerConsumptionPerMonthInKwh - structure.from;

    const more = structureDifference * structure.price;
    cost += more;

    if (structure.to && powerConsumptionPerMonthInKwh < structure.to) {
      break;
    }
  }

  // If no structure, directly multiply the two value
  if (billingCalculator.structure.length === 0) {
    cost += powerConsumptionPerMonthInKwh * billingCalculator.price;
  }

  return cost;
};

/*

const timeBasedKwhToYen = (billingCalculator, powerConsumptionPerMonthInKwh)=> {
  let cost = billingCalculator.baseCharge;
  if (powerConsumptionPerMonthInKwh === 0) {
    return cost;
  }

  for (const structure of billingCalculator.structure) {
    const hoursInADay = 24;
    if (!structure.to) {
      throw new Error('TimeBased BillingCalculator Requires both "from" and "to" times to be set.');
    } else {
      cost += ((structure.to - structure.from) / hoursInADay) * powerConsumptionPerMonthInKwh * structure.price;
    }
  }

  // If no structure, directly multiply the two value
  if (billingCalculator.structure.length === 0) {
    cost += powerConsumptionPerMonthInKwh * billingCalculator.price;
  }

  return cost;
};

*/

/**
 * @description Uses the power consumption per month to return the monthly bill based weighted time structure
 * @param billingCalculator
 * @param {number} powerConsumptionPerMonthInKwh power consumption in kwh
 * @return {number} Return the monthly bill in YEN
 */
const timeBasedKwhToYen = (billingCalculator: BillingCalculator, powerConsumptionPerMonthInKwh: number): number => {
  let cost = billingCalculator.baseCharge;
  if (powerConsumptionPerMonthInKwh === 0) {
    return cost;
  }

  for (const structure of billingCalculator.structure) {
    const hoursInADay = 24;
    if (!structure.to) {
      throw new Error('TimeBased BillingCalculator Requires both "from" and "to" times to be set.');
    } else {
      cost += ((structure.to - structure.from) / hoursInADay) * powerConsumptionPerMonthInKwh * structure.price;
    }
  }

  // If no structure, directly multiply the two value
  if (billingCalculator.structure.length === 0) {
    cost += powerConsumptionPerMonthInKwh * billingCalculator.price;
  }

  return cost;
};

/**
   * @description Uses the power consumption per month to return the monthly bill based on tepco's calculation
   * @param {number} powerConsumptionPerMonthInKwh power consumption in kwh
   * @return {number} Return the monthly bill in YEN
   */
export const monthlyKwhToYen = (billingCalculator: BillingCalculator, powerConsumptionPerMonthInKwh: number): number => {
  if (
    typeof powerConsumptionPerMonthInKwh !== 'number'
        || powerConsumptionPerMonthInKwh === undefined
        || isNaN(powerConsumptionPerMonthInKwh)
        || powerConsumptionPerMonthInKwh < 0
  ) {
    throw new Error('Expect A Valid Positive Number Type Input');
  }

  if (billingCalculator.type === 'timebased') {
    return timeBasedKwhToYen(billingCalculator, powerConsumptionPerMonthInKwh);
  }
  return standardKwhToYen(billingCalculator, powerConsumptionPerMonthInKwh);
};

const timeBasedYenToKwh = (billingCalculator: BillingCalculator, averageMonthlyBillInYen: number): number => {
  if (averageMonthlyBillInYen <= billingCalculator.baseCharge) {
    return 0;
  }

  if (billingCalculator.structure.length === 0) {
    return (averageMonthlyBillInYen - billingCalculator.baseCharge) / billingCalculator.price;
  }
  let averageRate = 0;
  const remainingBill = averageMonthlyBillInYen - billingCalculator.baseCharge;
  const hoursInADay = 24;
  if (billingCalculator.structure) {
    for (const structure of billingCalculator.structure) {
      if (structure.to) {
        averageRate += (structure.to - structure.from) / hoursInADay * structure.price;
      }
    }
  }
  return remainingBill / averageRate;
};

const standardYenToKwh = (billingCalculator: BillingCalculator, averageMonthlyBillInYen: number): number => {
  if (averageMonthlyBillInYen <= billingCalculator.baseCharge) {
    return 0;
  }

  if (billingCalculator.structure.length === 0) {
    return (averageMonthlyBillInYen - billingCalculator.baseCharge) / billingCalculator.price;
  }

  let power = 0;
  let remainingBill = averageMonthlyBillInYen - billingCalculator.baseCharge;

  if (billingCalculator.structure) {
    for (const structure of billingCalculator.structure) {
      if (structure.to) {
        // eslint-disable-next-line
        let v = (structure.to - structure.from) * structure.price
        if (v <= remainingBill) {
          remainingBill -= v;
          power += structure.to - structure.from;
        } else {
          power += remainingBill / structure.price;
          remainingBill = 0;
        }
      } else {
        power += remainingBill / structure.price;
        remainingBill = 0;
      }
    }
  }

  return power;
};

/**
   * @description Use the monthlyBill to estimate the power consumption per month
   * @param {number} averageMonthlyBill average monthly bill in YEN
   * @return {number} Return the power consumption per month in kwh
   */
export const monthlyYenToKwh = (billingCalculator: BillingCalculator, averageMonthlyBillInYen: number): number => {
  if (
    typeof averageMonthlyBillInYen !== 'number'
        || averageMonthlyBillInYen === undefined
        || isNaN(averageMonthlyBillInYen)
        || averageMonthlyBillInYen < 0
  ) {
    throw new Error('Expect A Valid Positive Number Type Input');
  }

  if (averageMonthlyBillInYen === 0) {
    return 0;
  }

  if (billingCalculator.type === 'timebased') {
    return timeBasedYenToKwh(billingCalculator, averageMonthlyBillInYen);
  }
  return standardYenToKwh(billingCalculator, averageMonthlyBillInYen);

  // let power = 0;

  // for (let j = 0; j < priceStructure.length; j++) {
  //   const structure = priceStructure[j];
  //   if (averageMonthlyBillInYen <= structure) {
  //     // Find the last structure and last price
  //     // set default last structure as 0 and last price as base charge

  //     const lastLadder = billingCalculator.structure[j].from;
  //     const lastPrice = priceStructure[j - 1] || billingCalculator.baseCharge;

  //     power = billingCalculator.structure[j].price === 0
  //       ? (billingCalculator.structure[j].to || 0)
  //       : lastLadder + (averageMonthlyBillInYen - lastPrice) / billingCalculator.structure[j].price;
  //     // The power is calculated and break the loop.
  //     break;
  //   } else if (j === priceStructure.length - 1) {
  //     // if
  //     power = (billingCalculator.structure[j].to || 0) + (averageMonthlyBillInYen - priceStructure[j]) / billingCalculator.price;
  //   }
  // }

  // // If no structure, power is the division of bill and price

  // return Math.max(power, 0);
};

/**
   * @description Use the billing calculator structure to generate a price structure for use of estimation of power consumption
   * @param {array} structure Billing calculator structure
   * @param {number} baseCharge Base charge of billing calculator
   * @return {array} The price structure in array [0, 200, 500]
   */
export const getPriceStructureFromBillingCalculatorStructure = (structure: Structure[], baseCharge: number): number[] => {
  // Generate the price structure from billing calculator structure

  const priceStructure: number[] = [];

  // The first structure

  if (structure.length > 0 && structure[0].to) {
    priceStructure.push(structure[0].to * structure[0].price + baseCharge);
  }

  for (let i = 1; i < structure.length; i++) {
    // Reach the end and break out
    if (!structure[i].to) {
      break;
    }

    const lastLadder = structure[i - 1].to || 0;
    const lastPrice = priceStructure[i - 1];
    priceStructure.push(((structure[i].to || 0) - lastLadder) * structure[i].price + lastPrice);
  }

  return priceStructure;
};
