// Default link for main site.
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { colors, mediaQueries as mq } from '../../../lib/styles';

const useStyles = makeStyles(() => ({
  FooterNav: {
    display: 'flex',
    flexDirection: 'row',
    [mq.smOnly]: {
      justifyContent: 'center',
      marginBottom: '20px',
    },
  },
  navGroup: {
    marginLeft: 25,
    [mq.smOnly]: {
      marginLeft: '0px',
      '& + :nth-child(2)': {
        marginLeft: 40,
      },
    },
  },
  FooterNav__link: {
    marginBottom: 24,
    fontFamily: "'Yu Gothic','ヒラギノ角ゴ ProN' , 'Hiragino Kaku Gothic ProN' , '游ゴシック' , '游ゴシック体',sans-serif",
    color: 'inherit',
    textDecoration: 'none',
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 1,
    marginTop: 16,
    display: 'block',
    [mq.smOnly]: {
      textAlign: 'center',
      fontSize: 14,
    },
    [mq.mdUp]: {
      '& + $FooterNav__link': {
        borderLeft: 'none',
      },
    },
  },
}));

type FooterNavLinkProps = {
  trackingTag?: string;
  dataAttr?: string;
  to: string;
  text: string;
};

const FooterNavLink: React.FC<FooterNavLinkProps> = ({ trackingTag, dataAttr, to, text }) => {
  const classes = useStyles();

  return (
    <Link
      className={clsx(classes.FooterNav__link, trackingTag)}
      data-attr={dataAttr}
      to={{ pathname: to }}
      // to={(location) => ({ ...location, pathname: to })}
    >
      {text}
    </Link>
  );
};

FooterNavLink.defaultProps = {
  trackingTag: undefined,
  dataAttr: undefined,
};

export default FooterNavLink;
