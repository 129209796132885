import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import MemoizeHelper from '../MemoizeHelper';
import { mediaQueries as mq } from '../../lib/styles';

const useStyles = makeStyles(() => ({
  SubHeading: {
    fontWeight: 600,
    marginTop: '1em',
    marginBottom: 0,
    [mq.smOnly]: {
      fontSize: 18,
    },
    [mq.mdUp]: {
      fontSize: 20,
    },
  },
}));

type SubHeadingProps = {
  className?: string;
};

const SubHeading: React.FC<SubHeadingProps> = ({ children, className }) => {
  const classes = useStyles();

  return (
    <h3 className={clsx(classes.SubHeading, className && className)}>{children}</h3>
  );
};

SubHeading.defaultProps = {
  className: undefined,
};

export default MemoizeHelper<SubHeadingProps>(SubHeading);
