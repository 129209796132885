import React, { useRef, useEffect } from 'react';
import { ChartData, ChartOptions } from 'chart.js';
import useChart from '../../lib/hooks/useChart';

type ChartProps = {
  height?: number;
  width?: number;
  data: ChartData | undefined;
  options: ChartOptions | undefined;
  type: 'bar' | 'doughnut';
};

const Chart: React.FC<ChartProps> = ({ height, width, data, options, type }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { createChart, updateChart, chart } = useChart(type, canvasRef);

  useEffect(() => {
    if (data && options) {
      if (chart) {
        updateChart(data, options);
      } else {
        createChart(data, options);
      }
    }
  }, [data, options]);

  return (
    <canvas height={height} width={width} ref={canvasRef} />
  );
};

Chart.defaultProps = {
  height: 290,
  width: 610,
};

export default Chart;
