import React, { Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { mediaQueries as mq, colors } from '../../lib/styles';

const useStyles = makeStyles(() => ({
  MobileNavButton: {
    width: 20,
    height: 16,
    zIndex: 110,
    border: 'none',
    padding: 0,
    backgroundColor: 'transparent',
    [mq.smOnly]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    [mq.mdUp]: {
      display: 'none',
    },
    '&$navOpen': {
      flexDirection: 'row',
      border: `2px solid ${colors.white}`,
      height: 28,
      width: 28,
      padding: 2,
    },
  },
  MobileNavButton__line: {
    content: '""',
    display: 'block',
    width: 20,
    height: 2,
    backgroundColor: colors.orange,
    transition: 'all .5s ease',
  },
  navOpen: {
    '& $MobileNavButton__line': {
      backgroundColor: colors.white,
    },
    '& span:nth-child(1)': {
      transform: 'rotate(45deg)',
      position: 'absolute',
      top: 27,
    },
    '& span:nth-child(2)': {
      opacity: 0,
      position: 'absolute',
    },
    '& span:nth-child(3)': {
      transform: 'rotate(-45deg)',
      position: 'absolute',
      top: 27,
    },
  },
}));

type MobileNavProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const MobileNavButton: React.FC<MobileNavProps> = ({ isOpen, setIsOpen }) => {
  const classes = useStyles();

  return (
    <button
      className={clsx(classes.MobileNavButton, isOpen && classes.navOpen)}
      type="button"
      onClick={() => setIsOpen(!isOpen)}
    >
      <span className={classes.MobileNavButton__line} />
      <span className={classes.MobileNavButton__line} />
      <span className={classes.MobileNavButton__line} />
    </button>
  );
};

export default React.memo(MobileNavButton);
