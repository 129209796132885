import React from 'react';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  InputWrap: {
    width: '100%',
    position: 'relative',
    '& + $InputWrap': {
      marginTop: 12,
    },
  },
}));

type InputWrapProps = {
  className?: string;
};

const InputWrap: React.FC<InputWrapProps> = ({ className, children }) => {
  const classes = useStyles();
  return (
    <FormControl
      className={clsx(classes.InputWrap, className && className)}
    >
      {children}
    </FormControl>
  );
};

InputWrap.defaultProps = {
  className: undefined,
};

export default InputWrap;
