import React, { useState, useContext, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import SearchPredictions from './SearchPredictions';
import searchIconImg from '../MiniMap/search-icon.svg';
import SearchAddressContext from '../../lib/context/SearchAddressContext';
import AutocompleteContext from '../../lib/context/AutocompleteContext';
import { colors, mediaQueries as mq } from '../../lib/styles';

const useStyles = makeStyles(() => ({
  Form: {
    position: 'relative',
    width: '100%',
  },
  MiniMap__searchIcon: {
    display: 'inline',
    position: 'absolute',
    right: 11,
    top: 10,
    background: `url(${searchIconImg}) 50%  no-repeat ${colors.white}`,
    cursor: 'pointer',
    zIndex: 10000,
    height: '48px',
    width: '48px',
    fontWeight: 500,
    [mq.smOnly]: {
      right: 4,
      top: 4,
      height: '40px',
      width: '40px',
    },
  },
}));

type AutocompleteSearchBoxProps = {
  address: string;
  setAddress: (newAddress: string) => void;
  onFocus?: () => void;
  showButton?: boolean;
  className: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: (event: React.FormEvent<HTMLFormElement> | React.SyntheticEvent, value: string) => void;
  placeholder?: string;
  predictionsClassName: string;
};

const AutocompleteSearchBox: React.FC<AutocompleteSearchBoxProps> = ({ address, setAddress, showButton, className, onChange, onSubmit, onFocus, placeholder, predictionsClassName, children }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const unmounted = useRef(false);
  const { state: addressState, dispatch } = useContext(SearchAddressContext);
  const { state: { predictions }, setPlaceName, setQuery, setPlaceId } = useContext(AutocompleteContext);
  const [focusedPredictionIndex, setFocusedPredictionIndex] = useState<number | null>(null);
  const [originalInputValue, setOriginalInputValue] = useState<string>('');
  const [shouldHidePredictions, setShouldHidePredictions] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [submitted, setSubmitted] = useState<React.FormEvent<HTMLFormElement> | React.SyntheticEvent | null>(null);
  const classes = useStyles();

  useEffect(() => {
    if (addressState.address && !dirty) {
      setAddress(addressState.address);
    }
    return () => {
      unmounted.current = true;
    };
  }, [addressState.address]);

  useEffect(() => {
    if (onSubmit && submitted !== null) {
      onSubmit(submitted, address);
    }
  }, [submitted]);

  useEffect(() => {
    if (predictions && focusedPredictionIndex !== null && predictions[focusedPredictionIndex]) {
      // Update the text in the search bar if an item of the prediction list is selected,
      // and there is a result in the prediction list with a description
      setAddress(predictions[focusedPredictionIndex].description.replace(/日本、/, '') || address);
    } else if (dirty) {
      setAddress(originalInputValue);
    }
  }, [focusedPredictionIndex]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDirty(true);
    setShouldHidePredictions(false);
    setOriginalInputValue(event.target.value);
    setQuery(event.target.value);
    setAddress(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  const handleOnBlur = () => {
    setTimeout(() => {
      if (!unmounted.current) {
        setShouldHidePredictions(true);
      }
    }, 300);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    setShouldHidePredictions(false);
    if (event.key === 'ArrowUp') {
      if (focusedPredictionIndex !== null && focusedPredictionIndex >= 0) {
        // Select the next item up in the prediction list
        // or set to null (and get the actual text entered in the search bar) if you're at the top of the list
        setFocusedPredictionIndex(focusedPredictionIndex > 0 ? focusedPredictionIndex - 1 : null);
      } else {
        // Select the last item of the prediction list
        setFocusedPredictionIndex(predictions.length - 1);
      }
    }
    if (event.key === 'ArrowDown') {
      if (focusedPredictionIndex !== null && focusedPredictionIndex >= 0) {
        // Select the next item down in the prediction list
        // or set to null (and get the actual text entered in the search bar) if you're at the bottom of the list
        setFocusedPredictionIndex(focusedPredictionIndex < predictions.length - 1 ? focusedPredictionIndex + 1 : null);
      } else {
        // Select the first item of the prediction list
        setFocusedPredictionIndex(0);
      }
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    event.persist();
    setShouldHidePredictions(true);
    inputRef.current?.blur();
    if (focusedPredictionIndex !== null && predictions[focusedPredictionIndex] && predictions[focusedPredictionIndex].place_id) {
      setPlaceId(predictions[focusedPredictionIndex].place_id);
    } else {
      setPlaceName(address);
    }
    if (address !== 'ドロップされたピンの家') {
      dispatch({ type: 'SET', payload: { address } });
    }
    setSubmitted(event);
  };

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
  };

  return (
    <form onSubmit={handleSubmit} className={classes.Form}>
      <input
        className={className}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleOnBlur}
        onFocus={handleFocus}
        value={address || ''}
        ref={inputRef}
      />
      {/* <SearchPredictions
        className={predictionsClassName}
        predictions={predictions}
        focusedPredictionIndex={focusedPredictionIndex}
        shouldHide={shouldHidePredictions}
        setAddress={setAddress}
        setPlaceName={setPlaceName}
        setShouldHidePredictions={setShouldHidePredictions}
      /> */}
    </form>
  );
};

AutocompleteSearchBox.defaultProps = {
  onFocus: undefined,
  showButton: false,
  onChange: undefined,
  onSubmit: undefined,
  placeholder: '住所を入力',
};

export default AutocompleteSearchBox;
