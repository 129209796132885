import React from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

type SelectInputProps = {
  className?: string;
  onChange?: (event: React.ChangeEvent<{ name: string; value: string | number | boolean; }>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  value: string | number | boolean;
  label: string;
  helperText?: string;
  id: string;
  name: string;
};

const SelectInput: React.FC<SelectInputProps> = ({ className, onChange, onBlur, value, children, id, helperText, name, label }) => {
  const handleChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    const { name: evtName, value: evtValue } = event.target;
    const validTypes = ['string', 'number', 'boolean'];
    if (onChange && evtName && validTypes.includes(typeof evtValue)) {
      onChange(event as React.ChangeEvent<{ name: string; value: string | number | boolean; }>);
    }
  };

  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select
        native
        value={value}
        inputProps={{
          name,
          id,
        }}
        onChange={handleChange}
        onBlur={onBlur}
        className={className}
        label={label}
      >
        { children }
      </Select>
      <FormHelperText>{helperText || '\u00a0'}</FormHelperText>
    </FormControl>
  );
};

SelectInput.defaultProps = {
  className: undefined,
  onChange: undefined,
  onBlur: undefined,
  helperText: undefined,
};

export default SelectInput;
