// 2024-09: 福岡市向けSuncleTOP開発

import React from 'react';
import clsx from 'clsx';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { makeStyles } from '@material-ui/core/styles';
import { colors, mediaQueries as mq } from '../../../../../lib/styles';
import useOnMobile from '../../../../../lib/hooks/useOnMobile';
import fukuokaLogo from '../../images/header-logo.png';
import invertedTriangleImg from '../../images/inverted-triangle.svg';

const useStyles = makeStyles(() => ({
  '@global': {
    // CssBaseline.tsxで定義されている設定をクリア
    body: {
      fontSize: 'initial',
    },
  },
  Header: {
    position: 'sticky',
    top: 0,
    width: '100%',
    backgroundColor: `${colors.white}`,
    zIndex: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.8em 0',
  },
  Header__Webkit: {
    // position: '-webkit-sticky',
  },
  Header__inner: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [mq.mdDown]: {
      flexDirection: 'column',
    },
    [mq.lgOnly]: {
      flexDirection: 'row',
      maxWidth: '1500px',
      paddingLeft: '40px',
      paddingRight: '40px',
    },
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  FukuokaLogo: {
    [mq.smOnly]: {
      width: '95px',
      height: '29px',
    },
    [mq.mdUp]: {
      width: '119px',
      height: '36px',
    },
  },
  FukuokaHeader__title: {
    [mq.smOnly]: {
      fontSize: '1em',
      marginLeft: '4px',
    },
    [mq.mdUp]: {
      fontSize: '1.375em',
      marginLeft: '30px',
    },
  },
  link__wrap: {
    display: 'flex',
    flexDirection: 'row',
    [mq.mdDown]: {
      width: '100%',
      justifyContent: 'space-evenly',
      borderTop: 'solid 1px #E1E1E1',
      marginTop: '10px',
      paddingTop: '10px',
    },
    [mq.lgOnly]: {
      minWidth: '600px',
      justifyContent: 'space-around',
    },
  },
  link__box: {
    [mq.mdDown]: {
      textAlign: 'center',
    },
  },
  link: {
    color: colors.black,
    textDecoration: 'none',
    display: 'flex',
    [mq.mdDown]: {
      flexDirection: 'column-reverse',
    },
    [mq.lgOnly]: {
      alignItems: 'center',
      marginLeft: '50px',
    },
  },
  link__img: {
    [mq.lgOnly]: {
      marginTop: '-5px',
    },
  },
  link__text: {
    [mq.mdDown]: {
      fontSize: '0.875em',
      margin: 0,
    },
    [mq.lgOnly]: {
      fontSize: '1.0625em',
      marginLeft: '2em',
    },
  },
  break: {
    [mq.lgOnly]: {
      display: 'none',
    },
  },
}));

const HeaderFukuoka: React.FC = () => {
  const classes = useStyles();
  const isMobile = useOnMobile();

  return (
    <header className={clsx(classes.Header, classes.Header__Webkit)}>
      <div className={classes.Header__inner}>
        <div className={classes.flexRow}>
          <a
            href="https://www.city.fukuoka.lg.jp/kankyo/k-seisaku/genre/02.html"
            target="_blank"
            className={classes.FukuokaLogo}
          >
            <img src={fukuokaLogo} alt="福岡市" />
          </a>
          <h1 className={classes.FukuokaHeader__title}>
            太陽光発電導入シミュレーション
          </h1>
        </div>
        <div className={classes.link__wrap}>
          <div className={classes.link__box}>
            <AnchorLink
              href="#how-to-use"
              offset={isMobile ? 130 : 110}
              className={classes.link}
            >
              <img src={invertedTriangleImg} className={classes.link__img} />
              <span className={classes.link__text}>
                シミュレーションの<br className={classes.break} />使い方
              </span>
            </AnchorLink>
          </div>
          <div className={classes.link__box}>
            <AnchorLink
              href="#subsidy"
              offset={isMobile ? 130 : 110}
              className={classes.link}
            >
              <img src={invertedTriangleImg} className={classes.link__img} />
              <span className={classes.link__text}>
                福岡市の<br className={classes.break} />補助金
              </span>
            </AnchorLink>
          </div>
          <div className={classes.link__box}>
            <AnchorLink
              href="#decarbonization"
              offset={isMobile ? 130 : 110}
              className={classes.link}
            >
              <img src={invertedTriangleImg} className={classes.link__img} />
              <span className={classes.link__text}>
                チャレンジ！<br className={classes.break} />脱炭素
              </span>
            </AnchorLink>
          </div>
        </div>
      </div>
    </header>
  );
};

export default React.memo(HeaderFukuoka);
