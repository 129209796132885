import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { mediaQueries as mq } from '../../lib/styles';

const useStyles = makeStyles(() => ({
  UnderlineHeading: {
    textAlign: 'center',
    marginBottom: 0,
    [mq.smOnly]: {
      fontSize: 26,
    },
    [mq.mdUp]: {
      fontSize: 40,
    },
    '& + $UnderlineHeading': {
      marginTop: 0,
    },
  },
  UnderlineHeading__line: {
    display: 'inline-block',
    background: 'linear-gradient(transparent 70%, #ffff00 70%)',
  },
  Lead: {
    textAlign: 'center',
    [mq.smOnly]: {
      fontSize: 18,
    },
    [mq.mdUp]: {
      fontSize: 26,
      marginBottom: 32,
    },
  },
}));

type UnderlineHeadingProps = {
  lead?: string;
};

const UnderlineHeading: React.FC<UnderlineHeadingProps> = ({ children, lead }) => {
  const classes = useStyles();

  return (
    <>
      <h2 className={classes.UnderlineHeading}><span className={classes.UnderlineHeading__line}>{children}</span></h2>
      {lead && <p className={classes.Lead}>{lead}</p>}
    </>
  );
};

UnderlineHeading.defaultProps = {
  lead: undefined,
};

export default UnderlineHeading;
