import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { mediaQueries as mq } from '../../lib/styles';
import lineImg from './titleline_side.png';

const useStyles = makeStyles(() => ({
  LineHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontWeight: 600,
    margin: 0,
    [mq.smOnly]: {
      padding: '0 20px',
      fontSize: 20,
    },
    [mq.mdOnly]: {
      padding: '0 28px',
    },
    [mq.lgOnly]: {
      padding: '0 35px',
    },
    [mq.mdUp]: {
      fontSize: 30,
    },
    '&::before, &::after': {
      display: 'block',
      content: '""',
      background: `url(${lineImg}) left top/100% no-repeat`,
      [mq.smOnly]: {
        width: 13,
        height: 2.5,
      },
      [mq.mdUp]: {
        width: 26,
        height: 5,
      },
    },
    '&::before': {
      marginRight: 8,
    },
    '&::after': {
      marginLeft: 8,
    },
  },
}));

const LineHeading: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <h2 className={classes.LineHeading}>{ children }</h2>
  );
};

export default LineHeading;
