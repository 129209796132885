import { useState, useEffect } from 'react';

const useOnMapMobile = () => {
  const [isMobile, setIsMobile] = useState(true);

  const resizeCallback = () => {
    if (window.innerWidth < 1081) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth > 1080) {
      setIsMobile(false);
    }

    window.addEventListener('resize', resizeCallback);
    return () => {
      window.removeEventListener('resize', resizeCallback);
    };
  }, []);
  return isMobile;
};

export default useOnMapMobile;
