import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import HeaderLogo from './HeaderLogo';
import MobileNavButton from './MobileNavButton';
import MobileNav from './MobileNav';
import { colors, mediaQueries as mq } from '../../lib/styles';
import useOnMapMobile from '../../lib/hooks/useOnMapMobile';
import useOnMobile from '../../lib/hooks/useOnMobile';
// 2024-03-05: 小田原市再エネポテンシャル見える化
import OdawaraNavLinks from './OdawaraNavLinks';
// 2024-09: 福岡市向けSuncleTOP開発
import HeaderFukuoka from '../../pages/cities/fukuoka/components/HeaderFukuoka/HeaderFukuoka';

const useStyles = makeStyles(() => ({
  Header: {
    position: 'fixed',
    width: '100%',
    backgroundColor: `${colors.white}`,
    zIndex: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [mq.smOnly]: {
      height: 56,
    },
    [mq.mdUp]: {
      height: 72,
    },
  },
  // 2024-03-05: 小田原市再エネポテンシャル見える化
  OdawaraHeader: {
    // ボタン、リンクの追加に伴い、小田原市のヘッダの高さを変更。
    height: 130,
    [mq.smOnly]: {
      // スマホの場合、「小田原市×Suncle」のロゴの下にボタン、リンクを表示。
      flexDirection: 'column',
    },
    [mq.mdUp]: {
      // タブレット、PCの場合、「小田原市×Suncle」のロゴの横にボタン、リンクを表示。
      flexDirection: 'row',
    },
  },
  Header__inner: {
    maxWidth: 1280,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [mq.smOnly]: {
      margin: '0 16px',
    },
    [mq.mdUp]: {
      margin: '0 24px',
      position: 'relative',
    },
  },
  navSpace: {
    [mq.smOnly]: {
      height: 56,
    },
    [mq.mdUp]: {
      height: 90,
    },
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tagline: {
    fontFamily: "'游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'Noto Sans JP', 'ヒラギノ角ゴ ProN' , 'Hiragino Kaku Gothic ProN' , '游ゴシック' , '游ゴシック体' , YuGothic , 'Yu Gothic' , 'メイリオ' , Meiryo , 'ＭＳ ゴシック' , 'MS Gothic' , HiraKakuProN-W3 , 'TakaoExゴシック' , TakaoExGothic , 'MotoyaLCedar' , 'Droid Sans Japanese' , sans-serif",
    marginTop: '9px',
    fontSize: '10px',
    lineHeight: 1.4,
    fontWeight: 500,
    marginLeft: '13px',
    color: `${colors.textGray}`,
    [mq.smOnly]: {
      fontSize: '9px',
      marginTop: '2px',
      marginLeft: '10px',
    },
  },
  tagLarger: {
    fontFamily: "'游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'Noto Sans JP', 'ヒラギノ角ゴ ProN' , 'Hiragino Kaku Gothic ProN' , '游ゴシック' , '游ゴシック体' , YuGothic , 'Yu Gothic' , 'メイリオ' , Meiryo , 'ＭＳ ゴシック' , 'MS Gothic' , HiraKakuProN-W3 , 'TakaoExゴシック' , TakaoExGothic , 'MotoyaLCedar' , 'Droid Sans Japanese' , sans-serif",
    fontSize: '12px',
    lineHeight: 2,
    fontWeight: 500,
    marginLeft: '13px',
    color: `${colors.textGray}`,
  },
  SharpFlex: {
    display: 'flex',
    [mq.smOnly]: {
      justifyContent: 'center !important',
    },
  },
  // 2024-03-05: 小田原市再エネポテンシャル見える化
  OdawaraFlex: {
    [mq.smOnly]: {
      // スマホの場合、「小田原市×Suncle」のロゴを中央寄せ
      display: 'flex',
      justifyContent: 'center',
      // 「小田原市×Suncle」の「×」が真ん中に来るように微調整
      padding: '0 40px 0 0',
    },
  },
  SharpBorder: {
    borderBottom: 'solid 0.25px #e6e6e6',
    paddingLeft: '1.5%',
    paddingRight: '1.5%',
    [mq.smOnly]: {
      paddingLeft: '5%',
      paddingRight: '5%',
      paddingTop: 14,
      paddingBottom: 9,
    },
  },
  SharpWidth: {
    maxWidth: 1200,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

type HeaderProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const Header: React.FC<HeaderProps> = ({ isOpen, setIsOpen }) => {
  const classes = useStyles();
  const isMapMobile = useOnMapMobile();
  const [path, setPath] = useState<string>();
  const isMobile = useOnMobile();
  const location = useLocation();

  useEffect(() => {
    if (location && location.pathname) {
      switch (location.pathname) {
        case '/odawara': {
          setPath('odawara');
          break;
        }
        // 2024-09: 福岡市向けSuncleTOP開発
        case '/fukuoka': {
          setPath('fukuoka');
          break;
        }
        case '/sim/tepcohometech': {
          setPath('enekari');
          break;
        }
        case '/sim/sharp': {
          setPath('sharp');
          break;
        }
        default: {
          setPath('suncle');
        }
      }
    }
  }, [location.pathname]);

  return (
    <>
      {
        (() => {
          switch (path) {
            // 2024-09: 福岡市向けSuncleTOP開発
            case 'fukuoka':
              return <HeaderFukuoka />;
            default:
              return (
                <div>
                  <header className={clsx({
                    [classes.Header]: true,
                    [classes.SharpBorder]: path === 'sharp',
                    // 2024-03-05: 小田原市再エネポテンシャル見える化
                    [classes.OdawaraHeader]: path === 'odawara',
                  })}
                  >
                    <div className={clsx({
                      [classes.Header__inner]: true,
                      [classes.SharpWidth]: path === 'sharp',
                      [classes.SharpFlex]: path === 'sharp',
                      // 2024-03-05: 小田原市再エネポテンシャル見える化
                      [classes.OdawaraFlex]: path === 'odawara',
                    })}
                    >
                      <div className={clsx({
                        [classes.flexRow]: true,
                        [classes.SharpFlex]: path === 'sharp',
                      })}
                      >
                        <HeaderLogo path={path} />
                        {!isMapMobile && path === 'suncle' && (
                          <span className={classes.tagline}>おうちで、クリーンエネルギー<br />降り注ぐ太陽を、電気のちからに。</span>
                        )}
                        {isMobile && path === 'suncle' && (
                          <span className={classes.tagline}>おうちで、クリーンエネルギー<br />降り注ぐ太陽を、電気のちからに。</span>
                        )}
                        {path === 'enekari' && (
                          <span className={classes.tagLarger}>初期費用０で始める省エネライフ</span>
                        )}
                      </div>
                      { path === 'suncle' && <MobileNavButton isOpen={isOpen} setIsOpen={setIsOpen} />}
                      { path === 'suncle' && <MobileNav isOpen={isOpen} setIsOpen={setIsOpen} />}
                    </div>
                    {/* 2024-03-05: 小田原市再エネポテンシャル見える化 */}
                    { path === 'odawara' && <OdawaraNavLinks /> }
                  </header>
                  <div className={classes.navSpace} />
                </div>
              );
          }
        })()
      }
    </>
  );
};

export default React.memo(Header);
