// Default link for main site.
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors, mediaQueries as mq } from '../../../lib/styles';

const useStyles = makeStyles(() => ({
  FooterNav: {
    display: 'flex',
    flexDirection: 'row',
    [mq.smOnly]: {
      justifyContent: 'center',
      marginBottom: '20px',
    },
  },
  navGroup: {
    marginLeft: '50px',
    [mq.smOnly]: {
      marginLeft: '0px',
      '& + :nth-child(2)': {
        marginLeft: '40px',
      },
    },
  },
  FooterNav__link: {
    marginBottom: '24px',
    fontFamily: "'Yu Gothic','ヒラギノ角ゴ ProN' , 'Hiragino Kaku Gothic ProN' , '游ゴシック' , '游ゴシック体',sans-serif",
    color: colors.textColor,
    textDecoration: 'none',
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 1,
    marginTop: 16,
    display: 'block',
    [mq.smOnly]: {
      textAlign: 'center',
      fontSize: 14,
    },
    [mq.mdUp]: {
      '& + $FooterNav__link': {
        borderLeft: 'none',
      },
    },
  },
}));

const FooterNavLayout: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <nav className={classes.FooterNav}>
      <div className={`${classes.navGroup} ${classes.FooterNav__link}`}>
        {children}
      </div>
    </nav>
  );
};

export default FooterNavLayout;
