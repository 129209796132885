import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors, mediaQueries as mq } from '../../lib/styles';

const useStyles = makeStyles(() => ({
  InfoListItem__heading: {
    [mq.smOnly]: {
      padding: '24px 1em .5em',
      width: '100%',
    },
    [mq.mdUp]: {
      padding: '24px 0 24px 64px',
      borderBottom: `1px solid ${colors.borderColor}`,
      width: 320,
    },
  },
  InfoListItem__content: {
    marginLeft: 0,
    borderBottom: `1px solid ${colors.borderColor}`,
    [mq.smOnly]: {
      padding: '0 1em 24px',
      width: '100%',
    },
    [mq.mdUp]: {
      padding: '24px 0',
      width: 'calc(100% - 320px)',
    },
  },
}));

type InfoListItemProps = {
  heading: string;
};

const InfoListItem: React.FC<InfoListItemProps> = ({ heading, children }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.InfoListItem__heading}>{heading}</div>
      <div className={classes.InfoListItem__content}>{children}</div>
    </>
  );
};

export default InfoListItem;
