import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import MemoizeHelper from '../MemoizeHelper';
import { mediaQueries as mq } from '../../lib/styles';

const useStyles = makeStyles(() => ({
  ArticleText: {
    '& + $ArticleText': {
      [mq.smOnly]: {
        marginTop: 16,
      },
      [mq.mdOnly]: {
        marginTop: 24,
      },
      [mq.lgOnly]: {
        marginTop: 32,
      },
    },
  },
}));

type ArticleTextProps = {
  className?: string;
};

const ArticleText: React.FC<ArticleTextProps> = ({ children, className }) => {
  const classes = useStyles();

  return (
    <p className={clsx(classes.ArticleText, className && className)}>{children}</p>
  );
};

ArticleText.defaultProps = {
  className: undefined,
};

export default MemoizeHelper<ArticleTextProps>(ArticleText);
