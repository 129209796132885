import React, { Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import NavLinks from './NavLinks';
import { mediaQueries as mq, colors } from '../../lib/styles';

const useStyles = makeStyles(() => ({
  MobileNav: {
    [mq.smOnly]: {
      backgroundColor: colors.orange,
      overflow: 'auto',
      zIndex: 100,
      position: 'absolute',
      left: 0,
      width: '100%',
      height: '100vh',
      transition: 'all .5s cubic-bezier(.895,.03,.685,.22)',
      transform: 'translate3d(0,-100%,0)',
      right: 0,
      top: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingTop: 100,
    },
  },
  navOpen: {
    [mq.smOnly]: {
      transform: 'translate3d(0,0,0)',
      opacity: 1,
    },
  },
}));

type MobileNavProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const MobileNav: React.FC<MobileNavProps> = ({ isOpen, setIsOpen }) => {
  const classes = useStyles();

  return (
    <nav className={clsx(classes.MobileNav, isOpen && classes.navOpen)}>
      <NavLinks onClick={() => setIsOpen(false)} />
    </nav>
  );
};

export default React.memo(MobileNav);
