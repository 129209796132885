import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MemoizeHelper from '../MemoizeHelper';
import { mediaQueries as mq } from '../../lib/styles';

const useStyles = makeStyles(() => ({
  flexRow: {
    flex: '1 1 0px',
    [mq.smOnly]: {
      width: '100%',
      flex: '1 1 100%',
    },
  },
}));

type FlexItemProps = {
  className?: string;
};

const FlexItem: React.FC<FlexItemProps> = ({ children, className }) => {
  const classes = useStyles();

  return <div className={clsx(classes.flexRow, className && className)}>{children}</div>;
};

FlexItem.defaultProps = {
  className: undefined,
};

export default MemoizeHelper<FlexItemProps>(FlexItem);
