import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import miniMapImg from './mini_map.jpg';
import cloudIllustration1Img from './cloud-illustration-1.png';
import cloudIllustration2Img from './cloud-illustration-2.png';
import houseIllustrationImg from './house-illustration.png';
import searchIconImg from './search-icon.svg';
import pinIconImg from './pin-icon.svg';
import { colors, mediaQueries as mq } from '../../lib/styles';
import useOnMobile from '../../lib/hooks/useOnMobile';

const useStyles = makeStyles(() => ({
  MiniMap: {
    display: 'flex',
    alignItems: 'center',
    [mq.mdDown]: {
      flexDirection: 'column',
      height: '450px',
      alignItems: 'stretch',
    },
    [mq.smOnly]: {
      height: '370px',
    },
  },
  MiniMap__inputContainer: {
    position: 'relative',
  },
  MiniMap__searchIcon: {
    display: 'inline',
    position: 'absolute',
    right: 11,
    top: 10,
    background: `url(${searchIconImg}) 50%  no-repeat ${colors.white}`,
    cursor: 'pointer',
    zIndex: 10000,
    height: '48px',
    width: '48px',
    fontWeight: 500,
    [mq.smOnly]: {
      right: 4,
      top: 4,
      height: '40px',
      width: '40px',
    },
  },
  MiniMap__searchInput: {
    borderRadius: 10,
    fontSize: 16,
    border: `1px solid ${colors.borderColor}`,
    color: colors.textColor,
    // background: `url(${searchIconImg}) calc(100% - 20px)  no-repeat ${colors.white}`,
    padding: '20px 50px 20px 20px',
    width: '100%',
    boxShadow: '10px 10px 20px rgba(0,0,0,.3)',
    marginRight: 8,
    fontWeight: 500,
    [mq.smOnly]: {
      border: `2px solid ${colors.borderColor}`,
      boxShadow: '10px 10px 20px rgba(0,0,0,.1)',
    },
  },
  MiniMap__form: {
    width: '100%',
    maxWidth: '420px',
  },
  flexItem: {
    flex: '1 1 0px',
  },
  item: {
    height: '320px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    [mq.mdDown]: {
      justifyContent: 'center',
    },
  },
  itemLeft: {
    backgroundColor: '#FAFAF7',
  },
  itemRight: {
    backgroundImage: `url(${miniMapImg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  itemContentLeft: {
    marginLeft: '40px',
    [mq.layoutBPUp]: {
      marginLeft: 'calc(100% - 640px)',
    },
    [mq.mdDown]: {
      marginLeft: 0,
    },
    [mq.smOnly]: {
      padding: '0 20px',
    },
  },
  itemContentRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: '1 1 0px',
    marginRight: '40px',
    [mq.layoutBPUp]: {
      marginRight: 'calc(100% - 640px)',
    },
    [mq.mdDown]: {
      justifyContent: 'center',
      marginRight: 0,
    },
  },
  H1Text: {
    lineHeight: 1.3,
    fontFamily: "'游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'Noto Sans JP', 'ヒラギノ角ゴ ProN' , 'Hiragino Kaku Gothic ProN' , '游ゴシック' , '游ゴシック体' , YuGothic , 'Yu Gothic' , 'メイリオ' , Meiryo , 'ＭＳ ゴシック' , 'MS Gothic' , HiraKakuProN-W3 , 'TakaoExゴシック' , TakaoExGothic , 'MotoyaLCedar' , 'Droid Sans Japanese' , sans-serif",
    letterSpacing: '-1px',
    fontWeight: 500,
    margin: '0',
    marginBottom: '0.15em',
    fontSize: '3.1em',
    [mq.layoutBPUp]: {
      fontSize: '3.75em',
    },
    [mq.smOnly]: {
      marginBottom: '0.5em',
      fontSize: 'clamp(2em, 10.2vw, 3.5em)',
    },
  },
  highlight: {
    color: `${colors.orange}`,
  },
  pinIconImg: {
    width: '1em',
    verticalAlign: 'middle',
    marginTop: '-0.2em',
  },
  cloudIllustration1Img: {
    position: 'absolute',
    width: '56px',
    top: '30px',
    left: '40px',
    [mq.layoutBPUp]: {
      left: 'calc(85% - 640px)',
      width: '66px',
    },
  },
  cloudIllustration2Img: {
    position: 'absolute',
    right: '5%',
    width: '60px',
    top: '60px',
    [mq.layoutBPUp]: {
      width: '78px',
    },
  },
  houseIllustrationImg: {
    position: 'absolute',
    maxWidth: '190px',
    bottom: '-30px',
    left: '20px',
    [mq.layoutBPUp]: {
      left: 'calc(80% - 640px)',
      bottom: '-25px',
      maxWidth: '230px',
    },
  },
}));

const MiniMap: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = useState('');
  const classes = useStyles();
  const isMobile = useOnMobile();

  const goToSearchPage = () => {
    if (value) {
      navigate(`/search${location.search ? `${location.search}&` : '?'}addr=${value}`);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <div className={classes.MiniMap}>
      <div className={`${classes.item} ${classes.itemLeft} ${classes.flexItem}`}>
        <div className={`${classes.flexItem}, ${classes.itemContentLeft}`}>
          <div className={classes.H1Text}>
            今すぐ<br />
            <img className={classes.pinIconImg} src={pinIconImg} alt="pin-icon" />
            <span className={classes.highlight}>シミュレーション</span>
          </div>
          {isMobile && (
            <form className={classes.MiniMap__form} onSubmit={goToSearchPage}>
              <div className={classes.MiniMap__inputContainer}>
                <input
                  className={classes.MiniMap__searchInput}
                  placeholder="住所を入力"
                  value={value}
                  onChange={handleChange}
                />
                {/* eslint-disable-next-line */}
                <span className={classes.MiniMap__searchIcon} onClick={goToSearchPage}/>
              </div>
            </form>
          )}
        </div>
        <img className={classes.cloudIllustration1Img} src={cloudIllustration1Img} alt="cloud-illustration" />
        <img className={classes.cloudIllustration2Img} src={cloudIllustration2Img} alt="cloud-illustration" />
        <img className={classes.houseIllustrationImg} src={houseIllustrationImg} alt="house-illustration" />
      </div>
      {!isMobile && (
        <div className={`${classes.item} ${classes.itemRight} ${classes.flexItem}`}>
          <div className={`${classes.flexItem}, ${classes.itemContentRight}`}>
            <form className={classes.MiniMap__form} onSubmit={goToSearchPage}>
              <div className={classes.MiniMap__inputContainer}>
                <input
                  className={classes.MiniMap__searchInput}
                  placeholder="住所を入力"
                  value={value}
                  onChange={handleChange}
                />
                {/* eslint-disable-next-line */}
                <span className={classes.MiniMap__searchIcon} onClick={goToSearchPage}/>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default MiniMap;
