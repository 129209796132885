import { useReducer, useEffect, useState, Dispatch, SetStateAction, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import SimulationContext from '../context/SimulationContext';
import SearchAddressContext from '../context/SearchAddressContext';

type ReducerState = {
  predictions: google.maps.places.AutocompletePrediction[];
  isLoading: boolean;
  error: string | null;
};

type ReducerAction = {
  type: 'LOADING',
} | {
  type: google.maps.places.PlacesServiceStatus,
  payload: {
    predictions: google.maps.places.AutocompletePrediction[];
  };
};

const reducer = (state: ReducerState, action: ReducerAction) => {
  // All cases, besides 'LOADING' and 'SET_SERVICES', are status codes provided from Google Autocomplete API's response.
  switch (action.type) {
    case 'LOADING': {
      return {
        ...state,
        isLoading: true,
      };
    }
    case 'OK': {
      if (action.payload) {
        return {
          ...state,
          predictions: action.payload.predictions,
          isLoading: false,
          error: null,
        };
      }
      return state;
    }
    case 'ZERO_RESULTS': {
      return {
        ...state,
        predictions: [],
        isLoading: false,
        error: null,
      };
    }
    case 'INVALID_REQUEST': {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }
    case 'REQUEST_DENIED': {
      return {
        ...state,
        isLoading: false,
        error: 'Invalid \'key\' parameter',
      };
    }
    case 'UNKNOWN_ERROR': {
      return {
        ...state,
        isLoading: false,
        error: 'Unknown error, refresh and try again.',
      };
    }
    default:
      return state;
  }
};

const initialState = {
  predictions: [] as google.maps.places.AutocompletePrediction[],
  isLoading: false,
  error: null,
};

type UseGoogleAutocompleteReturnValue = {
  state: ReducerState,
  setQuery: Dispatch<SetStateAction<string>>,
  setPlaceId: Dispatch<SetStateAction<string>>,
  setPlaceName: Dispatch<SetStateAction<string>>,
  setPlacesService: (placesService: google.maps.places.PlacesService) => void
};

const useGoogleAutocomplete = (): UseGoogleAutocompleteReturnValue => {
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { dispatch: simulationDispatch } = useContext(SimulationContext);
  const { state: addressState, dispatch: addressDispatch } = useContext(SearchAddressContext);
  const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService>();
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService>();
  const [query, setQuery] = useState<string>('');
  const [placeId, setPlaceId] = useState<string>('');
  const [placeName, setPlaceName] = useState<string>('');
  const [pendingSearch, setPendingSearch] = useState<string | null>(null);

  useEffect(() => {
    dispatch({ type: 'OK' as google.maps.places.PlacesServiceStatus, payload: { predictions: [] } });
  }, [location.pathname]);

  useEffect(() => {
    // Query for autocomplete suggestions
    if (autocompleteService) {
      if (query === '') {
        dispatch({
          type: google.maps.places.PlacesServiceStatus.OK,
          payload: {
            predictions: [],
          },
        });
      } else {
        if (!state.isLoading) {
          dispatch({
            type: 'LOADING',
          });
        }

        autocompleteService.getPlacePredictions(
          {
            input: query,
            componentRestrictions: { country: 'JP' },
            sessionToken: addressState.sessionToken,
          },
          (predictions: any, status: google.maps.places.PlacesServiceStatus) => {
            dispatch({
              type: status,
              payload: {
                predictions,
              },
            });
          },
        );
      }
    }
  }, [query]);

  useEffect(() => {
    // Query places service by place id from autocomplete suggestion
    if (placeId !== '' && placesService) {
      placesService.getDetails(
        {
          placeId,
          fields: ['name', 'formatted_address', 'place_id', 'geometry', 'address_components'],
          sessionToken: addressState.sessionToken,
        },
        lookupPlaceCallback,
      );
    }
  }, [placeId]);

  useEffect(() => {
    // Run a text search on places service for queries using the search box
    if (placeName !== '' && placesService) {
      placesService.textSearch({ query: placeName }, (places: any, status: google.maps.places.PlacesServiceStatus) => {
        lookupPlaceCallback(places[0], status);
      });
    } else {
      // Save to pending search if places service isn't initialized
      setPendingSearch(placeName);
    }
  }, [placeName]);

  useEffect(() => {
    // Run any pending searches once places service is initialized
    if (placesService && pendingSearch) {
      placesService.textSearch({ query: pendingSearch }, (places: any, status: any) => {
        lookupPlaceCallback(places[0], status);
      });
      setPendingSearch(null);
    }
  }, [placesService]);

  useEffect(() => {
    initAutocomplete();
  }, []);
  const lookupPlaceCallback = (place: any, status: any) => {
    if (!place || !place.geometry || status !== google.maps.places.PlacesServiceStatus.OK) {
      simulationDispatch({ type: 'ERROR' });
    } else {
      // Dispatch to simulation context once we have a lat/lng
      simulationDispatch({ type: 'VERIFY_GEOCODE', payload: { isInsideJapan: 'pending', coordinates: { lat: place?.geometry?.location?.lat(), lng: place?.geometry?.location?.lng() } } });
    }
  };

  const initAutocomplete = () => {
    if (window.google) {
      // Create the session token
      addressDispatch({ type: 'SET', payload: { sessionToken: new google.maps.places.AutocompleteSessionToken() } });
      // Initialize autocomplete service
      setAutocompleteService(new google.maps.places.AutocompleteService());
    } else {
      // Call init again if google isn't ready
      setTimeout(() => initAutocomplete(), 100);
    }
  };

  return { state, setQuery, setPlaceId, setPlaceName, setPlacesService };
};

export default useGoogleAutocomplete;
