import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { colors, mediaQueries as mq } from '../../lib/styles';

const useStyles = makeStyles(() => ({
  ParagraphText: {
    fontSize: '1em',
    margin: '0',
    marginBottom: '1.5em',
    fontWeight: 500,
    lineHeight: 1.6,
    color: `${colors.textGray}`,
    [mq.mdOnly]: {
      fontSize: '.8em',
    },
    [mq.smOnly]: {
      fontSize: '1em',
    },

  },
}));

type ParagraphTextProps = {
  className?: string;
};

const ParagraphText: React.FC<ParagraphTextProps> = ({ children, className }) => {
  const classes = useStyles();

  return (
    <p className={clsx(classes.ParagraphText, className)}>{children}</p>
  );
};

ParagraphText.defaultProps = {
  className: undefined,
};

export default ParagraphText;
