import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  Loading: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    left: 0,
    top: 0,
    transitionProperty: 'opacity',
    transitionDuration: '0.5s',
    opacity: 1,
    pointerEvents: 'none',
    zIndex: 10000,
  },
  Loading__bg: {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#ddd',
    opacity: 0.75,
  },
  Loading__icon: {
    width: 105,
    height: 120,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    margin: '-52px auto',
    '@media (max-width: 767px)': {
      transform: 'translate(-50%, -50%) scale(0.9, 0.9)',
      top: '55%',
      margin: '-49.4px auto',
    },
  },
  Loading__iconLine: {
    width: 22,
    height: 90,
    position: 'absolute',
    top: 15,
    transform: 'translateY(-50%) skew(-15deg)',
  },
  Loading__iconLine1: {
    backgroundColor: '#e99413',
    animation: '$anim_p01 0.5s infinite linear alternate',
    left: 27,
  },
  Loading__iconLine2: {
    backgroundColor: '#5a3087',
    animation: '$anim_p02 0.5s infinite linear alternate',
    right: 27,
  },
  fadeOut: {
    opacity: 0,
  },
  '@keyframes anim_p01': {
    '0%': {
      transform: 'translate(-2px, 7px) skew(-15deg)',
    },
    '50%': {
      transform: 'translateY(0) skew(-15deg)',
    },
    '100%': {
      transform: 'translate(2px, -7px) skew(-15deg)',
    },
  },
  '@keyframes anim_p02': {
    '0%': {
      transform: 'translate(2px, -7px) skew(-15deg)',
    },
    '50%': {
      transform: 'translateY(0) skew(-15deg)',
    },
    '100%': {
      transform: 'translate(-2px, 7px) skew(-15deg)',
    },
  },
  isContained: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 1,
  },
  isContained__bg: {
    width: '100%',
    height: '100%',
    opacity: 0.85,
  },
}));

type LoadingProps = {
  shouldLoad?: boolean;
  isContained?: boolean;
};

const Loading: React.FC<LoadingProps> = ({ shouldLoad, isContained }) => {
  const [shouldFadeOut, setShouldFadeOut] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    let timeout: any;
    if (shouldLoad) {
      setShouldFadeOut(false);
    } else {
      timeout = setTimeout(() => {
        setShouldFadeOut(true);
      }, 1000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [shouldLoad]);

  return (
    <div className={clsx(classes.Loading, shouldFadeOut && classes.fadeOut, isContained && classes.isContained)}>
      <div className={clsx(classes.Loading__bg, isContained && classes.isContained__bg)} />
      <div className={classes.Loading__icon}>
        <div className={clsx(classes.Loading__iconLine, classes.Loading__iconLine1)} />
        <div className={clsx(classes.Loading__iconLine, classes.Loading__iconLine2)} />
      </div>
    </div>
  );
};

Loading.defaultProps = {
  shouldLoad: false,
  isContained: false,
};

export default Loading;
