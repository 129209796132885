import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { mediaQueries as mq } from '../../lib/styles';

const useStyles = makeStyles(() => ({
  InputFeedback: {
    display: 'none',
    visibility: 'hidden',
    pointerEvents: 'none',
    position: 'absolute',
    top: 18,
    [mq.smOnly]: {
      right: 6,
    },
    [mq.mdUp]: {
      right: 8,
    },
  },
  ForSelect: {
    right: -28,
  },
  ShowFeedback: {
    display: 'block',
    visibility: 'visible',
  },
  InputFeedback__icon: {
    height: 20,
    width: 20,
    display: 'none',
  },
  'InputFeedback__icon--success': {
    fill: 'green',
  },
  'InputFeedback__icon--error': {
    fill: 'red',
  },
  ShowFeedbackIcon: {
    display: 'block',
  },
}));

type InputFeedbackProps = {
  hasError: boolean;
  isValid: boolean;
  isSelect?: boolean;
};

const InputFeedback: React.FC<InputFeedbackProps> = ({ hasError, isValid, isSelect }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.InputFeedback, (hasError || isValid) && classes.ShowFeedback, isSelect && classes.ForSelect)}>
      <svg
        className={clsx(classes.InputFeedback__icon, classes['InputFeedback__icon--success'], isValid && classes.ShowFeedbackIcon)}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path fill="none" d="M0 0h24v24H0V0zm0 0h24v24H0V0z" />
        <path
          d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
        />
      </svg>
      <svg
        className={clsx(classes.InputFeedback__icon, classes['InputFeedback__icon--error'], hasError && classes.ShowFeedbackIcon)}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          d="M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
        />
      </svg>
    </div>
  );
};

InputFeedback.defaultProps = {
  isSelect: undefined,
};

export default InputFeedback;
