import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import solarPanelIconImg from './images/solar-panel-icon.svg';
// import formIconImg from './images/form-icon.svg';
import questionIconImg from './images/question-icon.svg';
import speechIconImg from './images/speech-icon.svg';
import solarPanelIconWhiteImg from './images/solar-panel-icon-white.svg';
// import formIconWhiteImg from './images/form-icon-white.svg';
import questionIconWhiteImg from './images/question-icon-white.svg';
import speechIconWhiteImg from './images/speech-icon-white.svg';
import { mediaQueries as mq, colors } from '../../lib/styles';
import useOnMobile from '../../lib/hooks/useOnMobile';

const useStyles = makeStyles(() => ({
  NavLink: {
    fontFamily: "'游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'Noto Sans JP', 'ヒラギノ角ゴ ProN' , 'Hiragino Kaku Gothic ProN' , '游ゴシック' , '游ゴシック体' , YuGothic , 'Yu Gothic' , 'メイリオ' , Meiryo , 'ＭＳ ゴシック' , 'MS Gothic' , HiraKakuProN-W3 , 'TakaoExゴシック' , TakaoExGothic , 'MotoyaLCedar' , 'Droid Sans Japanese' , sans-serif",
    fontWeight: 500,
    lineHeight: 1,
    textDecoration: 'none',
    display: 'inline-block',
    marginLeft: 20,
    fontSize: '.9em',
    [mq.smOnly]: {
      color: colors.white,
      fontSize: '1.2em',
      marginLeft: 0,
      '& + $NavLink': {
        marginTop: 40,
      },
    },
    [mq.layoutBPUp]: {
      fontSize: '1em',
      '& + $NavLink': {
        marginLeft: 35,
      },
    },
    [mq.mdUp]: {
      color: colors.textColor,
      '&:hover': {
        color: colors.orange,
        opacity: 0.8,
      },
    },
  },
  linkItemImg: {
    width: '26px',
    verticalAlign: 'middle',
    marginRight: '5px',
    [mq.layoutBPUp]: {
      width: '30px',
    },
    [mq.smOnly]: {
      width: '32px',
    },
  },
}));

type NavLinksProps = {
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const NavLinks: React.FC<NavLinksProps> = ({ onClick }) => {
  const classes = useStyles();
  const isMobile = useOnMobile();
  const [shouldReturnOffsite, setShouldReturnOffsite] = useState('');

  const checkLandingPage = () => {
    if (window && window.location && window.location.pathname === '/sim/tepcohometech') {
      setShouldReturnOffsite('https://www.tepco-ht.co.jp/spv-cp2020/contact.html');
    }
  };

  useEffect(() => {
    checkLandingPage();
    window.addEventListener('popstate', checkLandingPage);
    return () => {
      window.removeEventListener('popstate', checkLandingPage);
    };
  }, []);

  return (
    <>
      { shouldReturnOffsite
        && (
        <Link
          to={shouldReturnOffsite}
          target="_blank"
          className={clsx(classes.NavLink, 'ga-click-tracking-target')}
          data-attr="return-to-sender"
          onClick={onClick}
        >
          サイトに戻る
        </Link>
        )}
      { !shouldReturnOffsite
          && (
            <>
              <Link
                type="link"
                to="/about"
                className={clsx(classes.NavLink, 'ga-click-tracking-target')}
                data-attr="top-about"
                onClick={onClick}
              >
                {!isMobile && (
                <img className={classes.linkItemImg} src={solarPanelIconImg} alt="solar-panel-icon" />
                )}
                {isMobile && (
                <img className={classes.linkItemImg} src={solarPanelIconWhiteImg} alt="solar-panel-icon-white" />
                )}
                サンクルとは
              </Link>
              <Link
                to="/faq"
                className={clsx(classes.NavLink, 'ga-click-tracking-target')}
                data-attr="top-faq"
                onClick={onClick}
              >
                {!isMobile && (
                <img className={classes.linkItemImg} src={questionIconImg} alt="question-icon" />
                )}
                {isMobile && (
                <img className={classes.linkItemImg} src={questionIconWhiteImg} alt="question-icon-white" />
                )}
                よくあるご質問
              </Link>
              <Link
                to="/contact"
                className={clsx(classes.NavLink, 'ga-click-tracking-target')}
                data-attr="top-contact"
                onClick={onClick}
              >
                {!isMobile && (
                <img className={classes.linkItemImg} src={speechIconImg} alt="speech-icon" />
                )}
                {isMobile && (
                <img className={classes.linkItemImg} src={speechIconWhiteImg} alt="speech-icon-white" />
                )}
                お問い合わせ
              </Link>
            </>
          )}
    </>
  );
};

export default React.memo(NavLinks);
