import React, { Dispatch, SetStateAction, createContext } from 'react';
import useGoogleAutocomplete from '../hooks/useGoogleAutocomplete';

type ReducerState = {
  predictions: google.maps.places.AutocompletePrediction[];
  isLoading: boolean;
  error: string | null;
  autocompleteService?: google.maps.places.AutocompleteService;
  placesService?: google.maps.places.PlacesService;
};

type ContextArgs = {
  state: ReducerState,
  setQuery: Dispatch<SetStateAction<string>>,
  setPlaceId: Dispatch<SetStateAction<string>>,
  setPlaceName: Dispatch<SetStateAction<string>>,
  setPlacesService: (placesService: google.maps.places.PlacesService) => void
};

const AutocompleteContext = createContext({} as ContextArgs);

export const AutocompleteContextProvider: React.FC = ({ children }) => {
  const { state, setQuery, setPlaceId, setPlaceName, setPlacesService } = useGoogleAutocomplete();

  AutocompleteContext.displayName = 'AutocompleteContext';
  return <AutocompleteContext.Provider value={{ state, setQuery, setPlaceId, setPlaceName, setPlacesService }}>{children}</AutocompleteContext.Provider>;
};

export default AutocompleteContext;
