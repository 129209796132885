import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { colors } from '../../lib/styles';

const useStyles = makeStyles((theme) => ({
  spinner: {
    color: colors.orange,
    marginTop: 16,
  },
}));

const PendingSpinner: React.FC = () => {
  const classes = useStyles();

  return <CircularProgress thickness={6} className={classes.spinner} />;
};

export default PendingSpinner;
